<template>
  <div class="container-menu">
    <item
      route="Dashboard"
      name="Dashboard"
      icon="dashboard"
      @atualizar="atualizar_menu"
      >Dashboard</item
    >
    <item
      route="estrategico"
      name="Estratégico"
      icon="plans"
      @atualizar="atualizar_menu"
      v-if="this.$store.getters.user.user.level !== 'blogger'"
      >Estratégico</item
    >
    <item
      route="Leads"
      name="Leads"
      icon="affiliate"
      @atualizar="atualizar_menu"
      v-if="this.$store.getters.user.user.level !== 'blogger'"
      >Leads</item
    >
    <item
      route="CampanhasLista"
      name="Funis"
      icon="funil"
      @atualizar="atualizar_menu"
      v-if="this.$store.getters.user.user.level !== 'blogger'"
      >Funis</item
    >
    <item
      route="CRM"
      name="CRM"
      icon="my-sales"
      @atualizar="atualizar_menu"
      v-if="this.$store.getters.user.user.level !== 'blogger'"
      >CRM</item
    >
    <item
      route="Apps"
      name="Aplicativos"
      icon="apps"
      @atualizar="atualizar_menu"
      v-if="this.$store.getters.user.user.level !== 'blogger'"
      >Aplicativos</item
    >
    <item
      route="MinhaConta"
      name="Minha Conta"
      icon="affiliate"
      @atualizar="atualizar_menu"
      >Minha Conta</item
    >
    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/img/icons/sign-out.svg" />
        Logout
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";
import Cookies from "js-cookie";

export default {
  props: {
    menu_mobile: Boolean,
  },
  data() {
    return {
      dashboard: false,
      vendas: false,
      cupom: false,
      recursos: this.$store.getters.recursos,
      level: this.$store.getters.user.user.level,
    };
  },
  components: {
    Item,
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
  },
  methods: {
    atualizar_menu() {
      this.$parent.menu_mobile = true;
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
  data() {
    return {
      dashboard: false,
      vendas: false,
    };
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  overflow: auto;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: var(--gray01) !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: var(--gray01);
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: var(--red);
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>
