import FlagService from "@/services/resources/FlagService";

const serviceFlag = FlagService.build();

export default {
  async getAllflegs({ commit }) {
    const allFlags = await serviceFlag.read("feature-flags");
    commit("setAllflags", allFlags);
    return allFlags;
  },

  async getUniqueFlag({ commit, dispatch, getters }, findFlag) {
    const flags = getters.flags;

    if (flags.length <= 0 || !flags.includes(findFlag)) {
      await dispatch("getAllflegs");
      return getters.flags.includes(findFlag)
    }
    return flags.includes(findFlag);
  },
};
