/**
 * This method show a toast
 * @param {Vue} vueIntance vue instance
 * @param {string} content toast content
 * @param {object} toastInfos toast informations (title, color, hide delay ...)
 */
const toast = (vueIntance, content, toastInfos) => {
    const parsedToast = {content, ... toastInfos}
    vueIntance.$refs.toasts.insertToast(parsedToast);
}  


export { toast };