<template>
  <div class="Menu-item" :class="{ active: active == route }" @click="click">
    <router-link :to="{ name: route }">
      <img :src="image" />
      {{ name }}
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    route: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    active() {
      var route_name = this.$route.name;
      if (route_name === "CampanhasListaUnica") {
        route_name = "CampanhasLista";
      }
      if (
        route_name === "Broadcast" ||
        route_name === "Lista de Broadcasts" ||
        route_name === "Lista de Eventos" ||
        route_name === "Criar Broadcast" ||
        route_name === "Lista-de-fanpages" ||
        route_name === "Fanpage" ||
        route_name === "Historic-integration3" ||
        route_name === "Historic-integration2" ||
        route_name === "Historic-integration" ||
        route_name === "Tags" ||
        route_name === "TagAutomacao"
      ) {
        route_name = "Apps";
      }
      return route_name;
    },
    image() {
      return require(`@/assets/img/icons/${this.icon}.svg`);
    },
  },
  methods: {
    click() {
      document.querySelector(".btn-menu").click();
      this.$emit("atualizar");
    },
  },
};
</script>

<style lang="scss" scoped>
.Menu-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  a {
    width: 100%;
    padding: 20px;
    display: flex;
    text-decoration: none;
    color: var(--gray01);
    font-size: 13.5px;
    font-weight: 600;

    img {
      margin-right: 20px;
      width: 17px;
    }
  }

  &:hover {
    background-color: var(--greenn);
    a {
      color: #fff;

      img {
        filter: invert(100%) brightness(1000%);
      }
    }
    cursor: pointer;
  }
}
.active {
  background-color: var(--greenn);

  a {
    color: #fff;

    img {
      filter: invert(100%) brightness(1000%);
    }
  }
  cursor: pointer;
}
</style>
