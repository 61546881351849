import Cookies from "js-cookie";
import router from "@/router/index";

export default {
  loginSuccess: (state, resp) => {
    state.blogs = resp.site;
    // localStorage.setItem('token', resp.token);
    Cookies.set("blogs", JSON.stringify(resp.site), { expires: 365 });
  },

  userSuccess: (state, resp) => {
    state.user = resp;
    state.blog = resp.blog;
    localStorage.setItem("user_data", JSON.stringify(resp));
  },

  verifyEmail: (state, resp) => {
    state.verifyEmail = resp;
    Cookies.set('verify_email', JSON.stringify(resp), {
        expires: 365
    });
  },

  tenantSubdomain: (state, resp) => {
    state.verifyEmail = resp;
    Cookies.set('tenantSubdomain', resp, {
        expires: 365
    });
  },

  userResourcesSuccess: (state, resp) => {
    state.resources = resp;
    localStorage.setItem("resources", JSON.stringify(resp));
  },

  userTagSuccess: (state, resp) => {
    state.tags = resp;
    localStorage.setItem("tags", JSON.stringify(resp));
  },

  userFormSuccess: (state, resp) => {
    state.forms = resp;
    localStorage.setItem("forms", JSON.stringify(resp));
  },

  userPageSuccess: (state, resp) => {
    state.pages = resp;
    localStorage.setItem("pages", JSON.stringify(resp));
  },

  userSellerSuccess: (state, resp) => {
    state.seller = resp;
    localStorage.setItem("seller", JSON.stringify(resp));
  },

  userDashboardGraph: (state, resp) => {
    state.dashboard = resp;
    localStorage.setItem("dashboard", JSON.stringify(resp));
  },

  userLogout: (state) => {
    state.user = null;
    state.blog = null;
    state.blogs = [];
    state.tags = [];
    state.resources = [];
    Cookies.remove("user");
    Cookies.remove("blog");
    Cookies.remove("blogs");
    localStorage.removeItem("tags");
    Cookies.remove("access_token");
    Cookies.remove("tenantSubdomain");
    Cookies.remove("verify_email");
    localStorage.clear();
    sessionStorage.clear();
    // window.$crisp.push(['do', 'chat:hide']);
    router.push("/adm/login");
  },

  userLogoutRdrk: (state) => {
    state.user = null;
    state.blog = null;
    state.blogs = [];
    state.tags = [];
    state.resources = {};
    Cookies.remove("user");
    Cookies.remove("blog");
    Cookies.remove("blogs");
    localStorage.removeItem("tags");
    Cookies.remove("resources");
  },
};
