import Rest from "@/services/Rest";

/**
 * @typedef {FlagService}
 */
export default class FlagService extends Rest {
  /**
   * @type {String}
   */
  static resource = "flag";
}
