export default {
  setDataOpportunities(state, data) {
    state.opportunities = data;
  },
  setPerPage(state, perPage) {
    state.pagination.perPage = perPage;
  },
  setTotal(state, total) {
    state.pagination.total = total;
  },
  setCurrentPage(state, currentPage) {
    state.pagination.currentPage = currentPage;
  },
  setLosOfGreennSoft(state, data) {
    state.losOfGreennSoft = data;
  },
  setWinOfGreennSofy(state, data) {
    state.winOfGreennSofy = data;
  },
  setSettingProft(state, data) {
    state.settingProft = data;
  },
  setOpportunitySelected(state, data) {
    state.opportunitySelected = data;
  },
  setFilterId: (state, id) => {
    state.filterOpportunities.id = id;
  },
  setFilterLeadName: (state, leadName) => {
    state.filterOpportunities.leadName = leadName;
  },
  setFilterLeadEmail: (state, leadEmail) => {
    state.filterOpportunities.leadEmail = leadEmail;
  },
  setFilterleadPhone: (state, leadPhone) => {
    state.filterOpportunities.leadPhone = leadPhone;
  },
  setFilterOpportunityStatus: (state, status) => {
    state.filterOpportunities.status = status;
  },
  setFilterEndDate: (state, endDate) => {
    state.filterOpportunities.endDate = endDate;
  },
  setFilterStartDate: (state, startDate) => {
    state.filterOpportunities.startDate = startDate;
  },

  setNewStatusOpportunity(state, status) {
    state.opportunitySelected.status = status;
  },

  setNewQuestion: (state, question) => {
    state.opportunitySelected.productQuestions.push(question)
  }
};
