<template>
  <BaseModal
    name="distribuition-leads-porcentagem"
    id="distribuition-leads-porcentagem"
    idModal="distribuition-leads-porcentagem"
    size="lg"
    @shown="openModal"
    title="Distribuição de leads"
  >
    <b-row v-if="!loading">
      <b-col cols="12" v-if="step == 1">
        <div class="top-dados">
          <div>
            <p>Distribui os Leads por meio de porcentagem.</p>
          </div>
        </div>
        <b-form>
          <b-row>
            <b-col>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  @change="mudaStatus"
                  v-model="percentage_distribution_opt"
                  name="is_main"
                  size="lg"
                  switch
                  class="w-100"
                >
                  <p class="info-checkbox mt-2">
                    Ativar distribuição de Leads por meio de porcentagem?
                  </p>
                </b-form-checkbox>
              </div>
              <b-form class="mt-3">
                <multiselect
                  @remove="removeSellerMultiselect"
                  @select="addSeller"
                  v-model="sellersSelected"
                  id="ajax"
                  label="email"
                  track-by="id"
                  placeholder="Selecione um vendedor"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  open-direction="bottom"
                  :options="sellers"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-no-results="true"
                  :hide-selected="true"
                >
                  <span slot="noOptions">Pesquise pelo vendedor</span>

                  <span slot="noResult">Oops! Nenhum vendedor encontrado.</span>
                </multiselect>
              </b-form>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col v-if="step == 2">
        <b-row class="Table-header mb-1">
          <b-col cols="4">Vendedor</b-col>
          <b-col cols="4">Porcentagem</b-col>
          <b-col cols="4" id="remove">Remover</b-col>
        </b-row>
        <div class="cont">
          <div class="seller_opt">
            <b-col>
              <p class="name">{{ sem_vendedor.display_name }}</p>
              <p class="email">{{ sem_vendedor.user_email }}</p>
            </b-col>
            <b-col
              v-b-tooltip.hover.top="
                'A porcentagem do sem vendedor irá se atualizar caso não distribuir 100%'
              "
              ><input
                disabled
                v-model="sem_vendedor.percentage"
                class="w-100"
                type="number"
                name="percentage"
                id="percentage"
            /></b-col>
            <b-col></b-col>
          </div>
          <div
            v-for="(item, index) in sellersSelected"
            :key="index"
            class="seller_opt"
          >
            <b-col>
              <p class="name">{{ item.first_name }}</p>
              <p class="email">{{ item.email }}</p>
            </b-col>
            <b-col
              ><input
                @input="validaPorcentagemTotal(`seller-${item.id}`, index)"
                :ref="'seller-' + item.id"
                v-model="item.percentage"
                class="w-100"
                type="number"
                name="percentage"
                id="percentage"
            /></b-col>
            <b-col
              ><img
                @click="removeSeller(index, item.percentage)"
                id="img-remove"
                src="@/assets/img/icons/x.svg"
            /></b-col>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton
        v-if="step == 1 && percentage_distribution_opt"
        variant="black"
        @click="nextStep"
      >
        Próxima etapa
      </BaseButton>
      <BaseButton
        v-b-tooltip.hover
        title="Ative a opção de distribuir leads por porcentagem para ir à próxima etapa"
        v-else-if="step == 1 && !percentage_distribution_opt"
        variant="black"
      >
        Próxima etapa
      </BaseButton>
      <BaseButton v-if="step == 2" variant="black" @click="step = 1">
        Voltar
      </BaseButton>
      <BaseButton v-if="step == 2" variant="primary" @click="salvarSellers">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import Vue from "vue";

import DistribuicaoService from "@/services/resources/DistribuicaoService";
const serviceDistribuicao = DistribuicaoService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  components: {
    Multiselect,
  },
  props: ["dadosTag"],
  data() {
    return {
      loading: false,
      step: 1,
      sellersSelected: [],
      sellers: [],
      percentage_distribution_opt: false,
      increment: null,
      sem_vendedor: {
        id: 0,
        display_name: "Sem vendedor",
        user_email: "Sem vendedor",
        percentage: 0,
      },
    };
  },
  computed: {
    vendedores() {
      return this.$store.getters.seller;
    },
  },
  methods: {
    openModal() {
      this.sem_vendedor.percentage = 100;
      this.fetchSellers();
      this.fetchOpts();
      this.step = 1;
      this.sellersSelected = [];
    },
    nextStep() {
      this.step = 2;
      this.retornaPorcentagemTotal();
      this.retornaSemVendedor();
    },
    fetchSellers() {
      // this.loading = true;
      this.sellers = [...this.vendedores];
      for (let i = 0; i < this.sellers.length; i++) {
        const element = this.sellers[i];
        element.percentage = 1;
      }
      serviceDistribuicao.read("").then((resp) => {
        this.loading = false;
        var sellers = resp;
        for (let i = 0; i < sellers.length; i++) {
          const element = sellers[i];
          var result = this.sellers.find((x) => x.id == element.user_id);
          if (result) {
            result.percentage = parseInt(element.user_percentage);
            this.sellersSelected.push(result);
          }
          if (element.user_id == 0) {
            this.sem_vendedor.percentage = parseInt(element.user_percentage);
          }
        }
      });
    },
    fetchOpts() {
      serviceSettings.read("/percentage-distribution").then((resp) => {
        this.percentage_distribution_opt =
          resp[0].meta_value == "true" ? true : false;
      });
    },
    retornaPorcentagemTotal() {
      var porcentagem_total = 0;
      for (let i = 0; i < this.sellersSelected.length; i++) {
        const element = this.sellersSelected[i].percentage;
        if (element !== "") {
          porcentagem_total += parseInt(element);
        }
        // else{
        //   porcentagem_total += 1;
        // }
      }

      return porcentagem_total;
    },
    validaPorcentagemTotal(vendedor, index) {
      if (
        this.$refs[vendedor][0].value < 1 &&
        this.$refs[vendedor][0].value !== ""
      ) {
        this.$grToast.toast("Porcentagem precisa ter ao menos 1 por cento", {
          title: "Distribuição de leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        Vue.set(this.sellersSelected[index], "percentage", 1);
        this.$refs[vendedor][0].value = 1;
      }

      if (this.retornaPorcentagemTotal() > 100) {
        var per = 0;
        for (let i = 0; i < this.sellersSelected.length; i++) {
          const element = this.sellersSelected[i].percentage;
          if (parseInt(element) < 100 && per + element <= 100) {
            per += parseInt(element);
          }
        }
        Vue.set(this.sellersSelected[index], "percentage", 100 - per);
        this.$refs[vendedor][0].value = 100 - per;

        this.$grToast.toast(
          "Porcentagem total maior que cem, ajuste a porcentagem dos vendedores",
          {
            title: "Distribuição de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }

      this.retornaSemVendedor();
    },
    mudaStatus() {
      if (this.percentage_distribution_opt) {
        let data = {
          meta_key: "equal_distribution_opt",
          meta_value: "false",
        };
        serviceSettings.create(data);

        let data2 = {
          meta_key: "percentage_distribution_opt",
          meta_value: "true",
        };

        serviceSettings.create(data2);
      } else {
        let data = {
          meta_key: "percentage_distribution_opt",
          meta_value: "false",
        };
        serviceSettings.create(data);
      }
    },
    salvarSellers() {
      this.loading = true;

      setTimeout(() => {
        if (this.percentage_distribution_opt) {
          let data = {
            meta_key: "equal_distribution_opt",
            meta_value: "false",
          };
          serviceSettings.create(data);

          let data2 = {
            meta_key: "percentage_distribution_opt",
            meta_value: "true",
          };
          serviceSettings.create(data2);
        } else {
          let data = {
            id: "percentage_distribution_opt",
            option_value: "false",
          };
          serviceSettings.create(data);
        }

        for (let i = 0; i < this.sellersSelected.length; i++) {
          const element = this.sellersSelected[i];
          this.sellersSelected[i].percentage = Math.abs(element.percentage);
        }

        if (this.sem_vendedor.percentage > 0) {
          this.sellersSelected.push({
            id: 0,
            tenant_id: this.$store.getters.user.user.tenant_id,
            percentage: this.sem_vendedor.percentage,
          });
        }

        let data = {
          id: "",
          users: this.sellersSelected,
        };

        serviceDistribuicao
          .create(data)
          .then(() => {
            this.$grToast.toast("Distribuição configurada com sucesso", {
              title: "Distribuição de leads",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide("distribuition-leads-porcentagem");
          })
          .catch(() => {
            this.$grToast.toast("Erro ao configurar distribuição", {
              title: "Distribuição de leads",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .finally(() => {
            this.sellersSelected = [];
            this.stopLoading();
          });
      }, 1000);
    },
    stopLoading() {
      this.loading = false;
    },
    removeSeller(index, percentage) {
      this.sellersSelected.splice(index, 1);
      this.retornaSemVendedor();
    },
    retornaSemVendedor() {
      var total = this.retornaPorcentagemTotal();

      if (total < 100) {
        var diff = 100 - parseInt(total);
        this.sem_vendedor.percentage = diff;
      } else {
        this.sem_vendedor.percentage = 0;
      }
    },
    removeSellerMultiselect() {
      this.retornaSemVendedor();
    },
    addSeller(e) {
      if (this.sem_vendedor.percentage == 0) {
        this.$grToast.toast(
          "Impossível adicionar vendedor, confira as porcentagens dos vendedores configurados",
          {
            title: "Distribuição de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.sellersSelected.pop();
        return;
      }
      setTimeout(() => {
        e.percentage = 1;
      }, 100);
      this.sem_vendedor.percentage = this.sem_vendedor.percentage - 1;
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

p + p {
  margin-top: 5px;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }

  .top-dados #limpar {
    margin-top: 15px;
  }
}

.form-relative {
  position: relative;
}

.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}

.seller_opt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  input {
    margin-top: 16px;
  }
}

#remove {
  text-align: center;
}

#img-remove {
  padding-left: 90px;
  cursor: pointer;
}

.name {
  font-weight: 600;
  color: var(--gray01);
}

.email {
  font-weight: 400;
  color: var(--gray01);
}

p {
  word-break: break-all;
}

.cont {
  height: 460px;
  overflow-y: auto;
}

.cont::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.cont::-webkit-scrollbar-track {
  background-color: transparent;
}

.cont::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}
</style>
