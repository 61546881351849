import Api from "@/services/API";

/**
 * @typedef {AuthService}
 */

export default class Auth extends Api {
  /**
   * @type {String}
   */
  static base = process.env.VUE_APP_API_HOST;
  // testespark.gpages.com.br

  /**
   * @type {String}
   */
  static resource = "/login";

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor(resource, options = {}, http = null) {
    super(Auth.normalize(Auth.base, resource), options, http);
  }

  /**
   * @return {this}
   */
  static build() {
    return new this(this.resource);
  }

  login(username, password, rdrk = null, authorize = null ,redirect_uri = null ,state = null) {
    let data = {
      email: username,
      password: password,
    };

    if(rdrk){
      data.rdrk = rdrk;
    }

    if(authorize && redirect_uri && state){
      data.authorize = authorize;
      data.redirect_uri = redirect_uri;
      data.state = state;
    }

    return this.post("", data);
  }

  logout() {
    return this.post();
  }
}
