<template>
<div>
    <nav class="navbar navbar-light bg-light">
        <span class="navbar-brand mb-0 h1">GDigital</span>
        <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="javascript:void(0);" @click="logout" >Sair</a>
      </li>
        </ul>
    </nav>

    <!-- Content -->
    <slot>

    </slot>

</div>
</template>

<script>
export default {

    methods:{
        logout(){
             this.$store
            .dispatch("logoutRequest")
            .catch(function(err) {
            });
        }
    }
    
}
</script>

<style lang="scss" scoped>

</style>