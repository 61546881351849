<template>
  <div class="container-logout">
    <div class="logout-btn">
      <router-link :to="{ name: 'MinhaConta' }" class="valid">
        <img
          :src="changeUrl(foto_usuario)"
          v-if="foto_usuario && render"
          class="foto-usuario valid"
          :class="{ contains: foto_usuario }"
        />
        <!-- <div
        @click="menuConfig"
        v-else
        style="
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background: #ededf0;
          margin-right: 20px;
          -o-object-fit: contain;
          object-fit: contain;
        "
        class="valid"
      ></div> -->
        <img
          v-else
          class="foto-usuario valid"
          src="@/assets/icons/picture-not-user.svg"
          alt="picture"
        />
      </router-link>

      <div class="space-flex valid">
        <div class="infos-user valid">
          <p class="valid">{{ userName() }}</p>
        </div>

        <a @click="menuConfig" class="valid">
          <img src="@/assets/img/icons/config.svg" class="item-config valid" />
        </a>

        <ul class="sub-menu valid" id="sub_config" v-show="sub_menu" @mouseleave="sub_menu = false">
          <li @click="sub_menu = false" class="valid">
            <router-link
              :is="
                $route.path === `/${dynamicRoute}/minha-conta`
                  ? 'a'
                  : 'router-link'
              "
              to="/dynamicRoute/minha-conta"
              class="valid"
              :class="{
                'router-link-exact-active': $route.path.includes('minha-conta'),
              }"
              v-if="level !== 'templater'"
            >
              <img src="@/assets/img/icons/user-config.svg" class="valid" />
              Meus dados
            </router-link>

            <router-link
              :is="
                $route.path === `/${dynamicRoute}/dominios`
                  ? 'a'
                  : 'router-link'
              "
              to="/dynamicRoute/dominios"
              class="valid"
              :class="{
                'router-link-exact-active': $route.path.includes('dominios'),
              }"
              v-if="level == 'owner' || level == 'admin'"
            >
              <img src="@/assets/img/icons/sites.svg" class="valid" />
              Domínios
            </router-link>

            <router-link
              :is="
                $route.path === `/${dynamicRoute}/configuracoes`
                  ? 'a'
                  : 'router-link'
              "
              class="valid"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('configuracoes'),
              }"
              to="/dynamicRoute/configuracoes"
              v-if="
                level == 'owner' ||
                level == 'admin' ||
                level == 'commercial_leader'
              "
            >
              <img src="@/assets/img/icons/company-config.svg" class="valid" />
              Configurações do sistema
            </router-link>

            <router-link
              :is="
                $route.path === `/${dynamicRoute}/usuarios`
                  ? 'a'
                  : 'router-link'
              "
              class="valid"
              :class="{
                'router-link-exact-active': $route.path.includes('usuarios'),
              }"
              to="/dynamicRoute/usuarios"
              v-if=" level == 'owner' ||
                level == 'admin' ||
                level == 'commercial_leader'
              "
            >
              <img src="@/assets/img/icons/users.svg" class="valid" />
              Usuários
            </router-link>
            <router-link
              :is="
                $route.path === `/${dynamicRoute}/selecionar-painel`
                  ? 'a'
                  : 'router-link'
              "
              class="valid"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('selecionar-painel'),
              }"
              to="/dynamicRoute/selecionar-painel"
              v-if="painel && painel.length > 1"
            >
              <img src="@/assets/img/icons/repeat.svg" class="valid" />
              Alterar Painel
            </router-link>

            <router-link
              class="valid"
              :class="{
                'router-link-exact-active': $route.path.includes('migracao'),
              }"
              to="/dynamicRoute/migracao"
              v-if="canImport()"
            >
              <img src="@/assets/img/icons/download.svg" class="valid mb-1" />
              Migrar Dados
            </router-link>

            <router-link
              :is="
                $route.path === `/${dynamicRoute}/midia` ? 'a' : 'router-link'
              "
              to="/dynamicRoute/midia"
              class="valid"
              :class="{
                'router-link-exact-active': $route.path.includes('midia'),
              }"
              v-if="level !== 'seller' && level !== 'gerent_seller'"
            >
              <img src="@/assets/img/icons/midia.svg" class="valid" />
              Mídia
            </router-link>

            <a class="valid" @click="contract_resource" v-if="!isBasic">
              <img class="valid" src="@/assets/img/icons/shop.svg" /> Comprar
              Extras
            </a>

            <a
              class="valid"
              target="_blank"
              :href="termsURL"
            >
              <img src="@/assets/img/icons/termos.svg" class="valid" />
              Termos de uso
            </a>

            <a href="#" id="logout-btn" @click.prevent="logout" class="valid"
              ><img
                src="@/assets/img/icons/sign-out.svg"
                class="valid"
              />Finalizar sessão</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import RedirectService from "@/services/resources/RedirectService";
const service = RedirectService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import contractPlano from "../../services/resources/contractPlan";
const contractPlan = contractPlano.build();

export default {
  props: ["isBasic"],
  data() {
    return {
      render: true,
      name_user: "",
      foto_usuario: "",
      sub_menu: false,
      level: this.$store.getters.user.user.level,
      painel: this.$store.getters.user.user.painels,
      dynamicRoute: Cookies.get("tenantSubdomain"),
      termsURL: process.env.URL_GREENN_TERMS
    };
  },

  methods: {
    contract_resource() {
      contractPlan
        .search()
        .then((r) => {
          let name = `${r.first_name} ${r.last_name}`;
          let phone = r.phone;
          let email = r.email;
          let document = r.document;
          let url = `https://gdigital.com.br/g-shopping/?fn=${name}&ph=${phone}&em=${email}&document=${document}&force=true`;
          window.open(url, "_blank");
        })
        .catch((err) => {});
    },
    canImport() {
      if (this.$store.getters.user.user.tenant_subdomain != "adm" && localStorage.getItem("has_imported") && (this.$store.getters.user.user.level == "admin" ||
          this.$store.getters.user.user.level === "owner")) {
        return true;
      }

      return false;
    },
    changeUrl(url) {
      var num = Math.random();
      return url + "?v=" + num;
    },
    userName() {
      if (this.$store.getters.user.user) {
        var metas = this.$store.getters.user.user.first_name;
        var metas2 = this.$store.getters.user.user.last_name;
        // return metas && metas2 ? metas + " " + metas2 : metas;
        return metas ? metas : metas2 ? metas2 : "";
      }
    },
    getPainel() {
      this.$store.getters.user.user.painels.id;
      this.painel = painels.length;
    },
    openLastG(project) {
      service.create({}).then((resp) => {
        let url =
          "https://" +
          resp.domain +
          "/gadmin/?rdrk=" +
          resp.ck +
          "#/" +
          project;
        window.open(url, "_blank");
      });
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch((err) => {});
    },
    minha_conta() {
      this.$router.push("/dynamicRoute/minha-conta");
    },
    menuConfig(e) {
      this.sub_menu = !this.sub_menu;
    },
    setProfilePicture() {
      this.render = false;
      var metas = this.$store.getters.user.user.metas;

      var photo;
      if (metas.length) {
        photo = metas.find((x) => x.meta_key === "photo");
      }

      if (photo && photo.meta_value) {
        this.foto_usuario = photo.meta_value;
      }

      setTimeout(() => {
        this.render = true;
      }, 500);
    },
  },
  mounted() {
    this.$root.$on("updateUserProfile", (data) => {
      this.setProfilePicture();
    });
    this.setProfilePicture();
  },
};
</script>
<style lang="scss" scoped>
.container-logout {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
}
.container-logout::before {
  content: "";
  width: 300px;
  height: 1px;
  background: #ededf0;
  display: block;
  margin-left: -40px;
  margin-bottom: 20px;
}

// novo rodape
.logout-btn {
  width: 100%;
  display: flex;
  margin-left: -8px;
}
.foto-usuario {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ededf0;
  margin-right: 27px;
  margin-left: -6px;
  object-fit: cover;
  cursor: pointer;
}
.foto-usuario.contains {
  background: none;
}

.space-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infos-user {
  display: block;
  margin-right: 25px;
  width: 110px;
}
.infos-user p {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}
.infos-user span {
  font-size: 12px;
}
.space-flex img.item-config {
  filter: invert(50%);
  width: 20px;
  cursor: pointer;
}

.sub-menu a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: var(--gray01);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}

.sub-menu a img {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 35px;
}
.sub-menu a:hover img {
  filter: invert(0);
}

img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}

/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 270px;
  // right: 50px;
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_config {
  bottom: 0 !important;
  top: unset !important;
  width: 310px;
}
.sub-menu li a {
  font-weight: 600;
  color: var(--gray01);
  font-size: 14px;
  padding: 10px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: var(--greenn);
}
.sub-menu li a.router-link-exact-active::before {
  left: -15px;
}

.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
#logout-btn:hover {
  color: var(--red);
}
.router-link-exact-active {
  text-decoration: none !important;
  color: var(--greenn) !important;

  img {
    filter: invert(0) !important;
  }

  .router-link-exact-active::before {
    left: -15px !important;
  }
}
</style>
