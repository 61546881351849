import Rest from "@/services/Rest";

/**
 * @typedef {contractPlan}
 */
export default class contractPlan extends Rest {
  /**
   * @type {String}
   */
  static resource = "user/contractPlan";
}
