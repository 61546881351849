<script setup>
import notifyDefaultIcon from '../assets/img/icons/notify_default.svg'
import notifyWarningIcon from '../assets/img/icons/notify_warning.svg'
import notifyDangerIcon from '../assets/img/icons/notify_danger.svg'
import notifyInfoIcon from '../assets/img/icons/notify_info.svg'
import notifySuccessIcon from '../assets/img/icons/notify_success.svg'
import { getCurrentInstance, ref, defineExpose, nextTick } from 'vue'

const { proxy } = getCurrentInstance();

const icons = {
    default: notifyDefaultIcon,
    warning: notifyWarningIcon,
    danger: notifyDangerIcon,
    info: notifyInfoIcon,
    success: notifySuccessIcon
}

const toasts = ref([]);

const closeToast = (id) => {
    const index = toasts.value.findIndex(toast => toast.id === id);
    if (index !== -1) {
        proxy.$refs[id][0].classList.add('hide-right');
        setTimeout(() => {
            toasts.value.splice(index, 1);
            recalculateToastPositions(); // Recalcula as posições após remover o toast
        }, 100);
    }
}

const recalculateToastPositions = () => {
    nextTick(() => {
        toasts.value.forEach((toast, index) => {
            const toastElement = proxy.$refs[toast.id][0];
            if (!toastElement) {
                return;
            }
            toastElement.style.transition = 'margin-top 0.4s ease';
            toastElement.style.marginTop = `${index * (toast.content.length >= 60 ? 150 : 100)}px`;
        });
    });
}

const insertToast = (toast) => {
    toast = { id: Date.now(), ...toast };
    toasts.value.push(toast);
    recalculateToastPositions(); // Recalcula as posições ao inserir um novo toast
    if (toast.autoHideDelay) {
        setTimeout(() => {
            closeToast(toast.id);
        }, toast.autoHideDelay < 1000 ?  1000 :  toast.autoHideDelay); // valida se o tempo de duração não é muito curto
    }
}
defineExpose({
  insertToast
});
</script>

<template>
    <div>
        <div v-for="toast in toasts" :key="toast.id" :ref="toast.id" :class="`gr-toast-box show-right ${toast.variant}`">
            <img class="gr-toast-icon" :src="icons[toast.variant]" alt="icon">
    
            <div class="gr-toast-header">
                <span class="gr-toast-title">
                    {{ toast.title }}
                </span>
                
                <button @click="closeToast(toast.id)" class="gr-toast-close" src="@/assets/icons/close.svg" alt="close">×</button>
            </div>
            
            <div class="gr-toast-content">
                <span>
                    {{ toast.content }}
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.gr-toast-box {
    position: fixed;
    max-width: 400px;
    width: 100%;
    top: 7px;
    right: 8px;
    margin: 0;
    z-index: 999999;
    font-size: 14px;
    background-color: hsla(0, 0%, 100%, 0.85);
    background-clip: initial;
    border: 1px solid;
    box-shadow: none;
    border-radius: 10px;
    backdrop-filter: blur(0);
    padding: 20px 30px 20px 50px;
    outline: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: margin-top 0.4s ease; /* Adiciona transição suave na posição */
}
.gr-toast-icon {
    position: absolute;
    width: 18px;
    height: 17px;
    left: 20px;
    top: 22px;
}
.gr-toast-title {
    font-weight: 600;
}
.gr-toast-content span {
    color: #5f5c6b;
    line-height: 1.5;
    font-size: 14px;
}
.gr-toast-close {
    position: absolute;
    top: 10px;
    right: 15px;
    opacity: 0.5;
    outline: none;
    border: none;
    text-shadow: none;
    line-height: 1;
    color: #5f5c6b;
    font-weight: 500;
    font-size: 25px;
    background: transparent;
}
.default {
    background-color: #f6f6f7;
    border-color: var(--gray01);
    .gr-toast-title {
        color: var(--gray01);
    }
}
.warning {
    background-color: #fffcf5;
    border-color: var(--orange);
    .gr-toast-title {
        color: var(--orange);
    }
}
.danger {
    background-color: rgb(255, 243, 245);
    border-color: var(--red);
    .gr-toast-title {
        color: var(--red);
    }
}
.info {
    background-color: rgb(244, 245, 253);
    border-color: var(--bluee);
    .gr-toast-title {
        color: var(--bluee);
    }
}
.success {
    background-color: #f2fefa;
    border-color: var(--greenn);
    .gr-toast-title {
        color: var(--greenn);
    }
}
.show-right {
    animation: fadeInRight .4s ease-in-out forwards;
}

.hide-right {
    animation: fadeOutRight .4s ease-in-out forwards;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
</style>