import  getters from './getters'
import  mutations from './mutations'

const state = {
  messageAlert: '',
  bgAlert: '',
  loading: false
}

export default {
  state,
  getters,
  mutations,
}