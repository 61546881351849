<script setup>
import { getCurrentInstance, computed, inject, ref, onMounted } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "@/utils/useStore";


import GreennService from "@/services/resources/GreennService";
const serviceGreenn = GreennService.build();
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

const vm = getCurrentInstance();

import {
  GrDropDownContent,
  GrDropDownSeparator,
  GrParagraph,
} from "@/components/GrComponents";

const toggleDropdown = inject("toggleDropdown");
const AllRoutesSidebar = inject("AllRoutesSidebar");

if (!toggleDropdown) {
  throw new Error("toggleDropdown not provided");
}

if (!AllRoutesSidebar) {
  throw new Error("AllRoutesSidebar not provided");
}

const router = useRoute();
const store = useStore();

const props = defineProps({
  align: { type: String, default: "left" },
  size: { type: String, default: "lg" },
});

const isClient = computed(() => {
  return store.getters["getIsClient"];
});
const sellerTags = ref(false);

const isHeaven = computed(() => {
  return store.getters["getIsHeaven"];
});

const dynamicRoute = ref(Cookies.get("tenantSubdomain") || "adm");

const validateRouteWithdrawalQuery = computed(() => {
  if (
    router.name === "Statements" &&
    router?.query?.action === "withdrawal-request"
  )
    return true;
  return false;
});

const isShowSales = computed(() => {
  const salesRoute = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Sales"
  );
  return salesRoute ? salesRoute.access && !isClient.value : true;
});

const isShowProducts = computed(() => {
  const salesProducts = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Products"
  );
  return salesProducts ? salesProducts.access && !isClient.value : true;
});

const isShowLinks = computed(() => {
  const salesLinks = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Links"
  );
  return salesLinks ? salesLinks.access && !isClient.value : true;
});

const isShowCheckouts = computed(() => {
  const salesCheckouts = AllRoutesSidebar.value.find(
    (routeItem) => routeItem.route === "Checkouts"
  );
  return salesCheckouts ? salesCheckouts.access && !isClient.value : true;
});

const isShowWithdrawAndExtract = computed(() => {
  return !store.getters.setPanel && !isClient.value;
});
const level = computed(() => {
  return store.getters.user.user.level;
});

const isEmptyShortcuts = computed(() => {
  if (isShowWithdrawAndExtract.value) return true;

  let validateAll = false;

  if (
    isShowSales.value &&
    isShowProducts.value &&
    isShowLinks.value &&
    isShowCheckouts.value
  ) {
    return validateAll;
  }

  if (
    isShowSales.value ||
    isShowProducts.value ||
    isShowLinks.value ||
    isShowCheckouts.value
  ) {
    return !validateAll;
  }
  if (
    !isShowSales.value ||
    !isShowProducts.value ||
    !isShowLinks.value ||
    !isShowCheckouts.value
  ) {
    return validateAll;
  }

  return !validateAll;
});

const linkGreenn = async () => {
  await serviceGreenn.createId({id: `rdr-greenn`}).then((resp) => {
    if(resp.rdr) {
      window.open(`${process.env.BASE_URL_GREENN_ADM}?rdr=${resp.rdr}`, '_blank');
    }else{
      vm.proxy.$grToast.toast("Você não possui uma conta no Greenn", {
        title: "Greenn Sales",
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });

      setTimeout(() => {
        window.open('https://adm.greenn.com.br/registro');
      }, 3000);
    }
  }).catch((err) => {
     vm.proxy.$grToast.toast("Você não possui uma conta no Greenn", {
        title: "Greenn Sales",
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });
      setTimeout(() => {
        window.open('https://adm.greenn.com.br/registro');
      }, 3000);
  })
};

const linkClub = async () => {
  await serviceGreenn.createId({id: `rdr-club`}).then((resp) => {
    if(resp && resp.string) {
      window.open(`${process.env.BASE_URL_CLUB_ADM}?rdr=${resp.string}`, '_blank');
    }
  }).catch((err) => {
    if(err.response.status === 422){
      vm.proxy.$grToast.toast("Para acessar, primeiro é necessario associar um produto do Greenn ao Club.", {
        title: "Greenn Sales",
        variant: "warning",
        autoHideDelay: 5000,
        appendToast: true,
      });
      return;
    }
    vm.proxy.$grToast.toast("Você não possui uma conta no Greenn", {
      title: "Greenn Sales",
      variant: "info",
      autoHideDelay: 5000,
      appendToast: true,
    });

    setTimeout(() => {
      window.open('https://adm.greenn.com.br/registro');
    }, 3000);
  })
};

onMounted(() => {
  if (store.getters.user.user.level == 'seller') {
      serviceSettings
        .read({id: 'seller'})
        .then((resp) => {
          sellerTags.value = resp ? resp.find((item) => item.meta_key == 'change_tag').meta_value : true
        })
    }
});
</script>
<template>
  <GrDropDownContent :align="props.align" size="lg">
    <div class="container-apps" v-if="level === 'owner'">
      <div class="apps">
        <GrParagraph
          class="gr-apps-text"
          color="gray-400"
          font-size="base"
          weight="semibold"
          >Ecossistema</GrParagraph
        >
        <div class="apps-grid">
          <div class="gr-app">
            <a @click.stop>
              <div class="icon greenn-sales">
                <img
                  src="@/assets/img/gr-icons/greenn_sales-app.png"
                  alt="app"
                />
              </div>

              <GrParagraph color="gray-400" font-size="xsm" weight="normal">
                Greenn Sales
              </GrParagraph>
            </a>
          </div>

          <div class="gr-app">
            <div class="icon greenn">
              <a href="javascript:;" @click="linkGreenn">
                <img src="@/assets/img/gr-icons/greenn-app.png" alt="app" />
              </a>
            </div>

            <GrParagraph color="gray-400" font-size="xsm" weight="normal">Greenn Adm</GrParagraph>
          </div>

          <div class="gr-app" @click="linkClub">
            <div class="icon greenn-club">
              <a href="javascript:;">
                <img
                src="@/assets/img/gr-icons/club-app.png"
                alt="app"
                />
              </a>
            </div>

            <GrParagraph color="gray-400" font-size="xsm" weight="normal"
              >Greenn Club</GrParagraph
            >
          </div>
        </div>
      </div>
    </div>
    
    <GrDropDownSeparator v-if="isEmptyShortcuts && level == 'owner'" />

    <div class="container-apps" v-if="isEmptyShortcuts">
      <div class="apps">
        <GrParagraph
          class="gr-apps-text"
          color="gray-400"
          font-size="base"
          weight="semibold"
          >Atalhos</GrParagraph
        >
        <div class="apps-grid">
          <div class="gr-app" @click="toggleDropdown">
            <router-link :to="'/' + dynamicRoute + '/apps/broadcast'" class="app-shortcut">
              <div class="icon">
                <img src="@/assets/img/gr-icons/sales-shortcut.svg" alt="app" />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">Broadcast</GrParagraph></router-link
            >
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="level !== 'seller'">
            <router-link :to="'/' + dynamicRoute + '/apps/lead-ads'" class="app-shortcut">
              <div class="icon">
                <img
                src="@/assets/img/gr-icons/products-shortcut.svg"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">Lead Ads</GrParagraph>
            </router-link>
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="level !== 'seller' || sellerTags" >
            <router-link :to="'/' + dynamicRoute + '/apps/tags'" class="app-shortcut">
              <div class="icon">

                <img
                src="@/assets/img/gr-icons/withdraw-shortcut.svg"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">Gest. Tags</GrParagraph>
            </router-link>
          </div>
          <div class="gr-app" @click="toggleDropdown" v-if="level == 'owner' || level == 'admin' || level == 'commercial_leader'">
            <router-link :to="'/' + dynamicRoute + '/apps/pixel'" class="app-shortcut">
              <div class="icon">
                <img
                src="@/assets/img/gr-icons//extract-shortcut.svg"
                alt="app"
                />
              </div>
              <GrParagraph color="gray-400" font-size="xsm" weight="normal">Pixel</GrParagraph>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </GrDropDownContent>
</template>

<style scoped lang="scss">
.container-apps {
  padding: var(--spacing-4);
  width: var(--w-full);

  .apps {
    display: grid;
    align-items: center;
    gap: var(--spacing-3);

    .gr-apps-text {
      padding-left: var(--spacing-3) !important;
    }

    .apps-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-3);

      @media (max-width: 350px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .gr-app {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
       
        gap: var(--spacing-1-5);
        
        .icon{
          width: var(--w-16);
          height: var(--w-16);

          display: flex;
          align-items: center;
          justify-content: center;

          transition: all 0.3s;

          border: 1px solid transparent;
          border-radius: var(--radius-lg);
          
          &.greenn-club {
            background-color: #E1F4F3;
            &:hover {
              border: 1px solid #009488 !important;
            }
          }
          
          &.greenn-sales {
            background-color: var(--primary-50);
            border: 1px solid #a1cd3a !important;
          }
          
          &.greenn {
            background-color: #E1F4F3;
              &:hover {
                border: 1px solid #009488 !important;
              }
          }

          &.heaven,
          &.envios {
            &:hover {
              border: 1px solid var(--secondary-500) !important;
            }
          }
        }
          a {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          gap: var(--spacing-1-5);
          text-decoration: none;
          border-radius: var(--radius-lg);

          img {
            transition: var(--transition);
            border-radius: var(--radius-lg);

            width: 50%;
          }

         
          &.app-shortcut {
            .icon{
              width: var(--w-16);
              height: var(--w-16);
              display: flex;
              align-items: center;
              justify-content: center;

              background-color: #F0F2F5;
              transition: all 0.3s;

              img{
                width: 100%;
              }
            }

            &.router-link-active img {
              border: 1px solid var(--gray-500);
              &:hover {
                border: 1px solid var(--gray-500);
              }
            }

            &.statementsQuery img {
              border: 1px solid transparent !important;
            }
            img {
              &:hover {
                border: 1px solid var(--gray-200);
              }
            }
          }
        }
      }
    }
  }
}
</style>
