import Cookies from "js-cookie";
import AuthService from "@/services/resources/AuthService";
import RevokeService from "@/services/resources/RevokeService";
import UserResourcesService from "@/services/resources/UserResourcesService";

import router from "@/router/index";

export default {
  //fazer login do OAuth 2
  loginRequest(context, payload) {
    var service = AuthService.build();
    return new Promise((resolve, reject) => {
      service
        .login(payload.username, payload.password, payload.rdrk ? payload.rdrk : null,
          payload.authorize ? payload.authorize : null,
          payload.redirect_uri ? payload.redirect_uri : null,
          payload.state ? payload.state : null
        )
        .then((resp) => {
          if (resp.token) {
            context.dispatch("blogSelected", resp);
            resolve(resp);
          } 
          else if(resp.redirect){
            if (resp.redirect && resp.redirect.length) {
              window.open(resp.redirect, "_self");
            }
          }
            else {
            Cookies.remove("access_token");
            reject(false);
          }
        })
        .catch((err) => {
          Cookies.remove("access_token");
          reject(false);
        });
    });
  },

  verifyEmail(context, payload) {
    context.dispatch("userRequest");
  },

  blogSelected2(context, payload) {
    Cookies.set("access_token", "Bearer " + payload.token, { expires: 365 });
    context.commit("authSuccess", "Bearer " + payload.token);
    context.dispatch("userRequest2");
  },

  blogSelected3(context, payload) {
    Cookies.set("access_token", "Bearer " + payload.token, { expires: 365 });
    context.commit("authSuccess", "Bearer " + payload.token);
    context.dispatch("userRequest3");
  },

  //funcao chamada quando o blog for selecionado

  blogSelected(context, payload) {
    Cookies.set("access_token", "Bearer " + payload.token, { expires: 365 });
    context.commit("authSuccess", "Bearer " + payload.token);
    context.dispatch("userRequest");
  },

  fetchTags(context, payload) {
    context.dispatch("tagRequest");
  },

  fetchAll(context, payload) {
    context.dispatch("allDataUser");
  },

  fetchSellers(context, payload) {
    context.dispatch("sellerRequest");
  },

  updateDashboardActions(context, payload) {
    context.dispatch("updateDashboard");
  },

  async updateUserProfile(context, payload) {
    await context.dispatch("updateUser");
  },

  logoutRequest: ({ commit, dispatch }) => {
    // Since the logout process not need to make a request
    // just remove the cookies and redirect
    Cookies.remove("access_token");
    Cookies.remove("tenantSubdomain");
    Cookies.remove("verify_email");
    localStorage.clear();
    sessionStorage.clear();
    commit("authLogout");
    commit("userLogout");
  },

  logoutToRdrkRequest: ({ commit, dispatch }) => {
    Cookies.remove("access_token");
    commit("authLogout");
    commit("userLogoutRdrk");
  },

  getResource: ({ commit, dispatch }) => {
    var service = UserResourcesService.build();
    return new Promise((resolve, reject) => {
      service
        .get()
        .then((resp) => {
          commit("userResourcesSuccess", resp);
          resolve(true);
        })
        .catch((err) => {
          reject(false);
        });
    });
  },
};
