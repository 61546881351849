<template>
  <div class="trial-tag" :class="missingDays <= 0 ? 'last-day' : ''">
    <div>
      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill-opacity="0.3"/>
        <path d="M17.5 14.1V17.5M17.5 20.9H17.5085M26 17.5C26 22.1944 22.1944 26 17.5 26C12.8056 26 9 22.1944 9 17.5C9 12.8056 12.8056 9 17.5 9C22.1944 9 26 12.8056 26 17.5Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span v-html="trialText"></span>
    </div>
    <button @click="upgradePlan">Falar com nosso time</button>
  </div>
</template>
<script>
import moment from "moment";
import TrialService from "@/services/resources/TrialService";
const serviceTrial = TrialService.build();

export default {
  data() {
    return {
      trialDate: '',
      missingDays: undefined,
    };
  },
  methods: {
    upgradePlan() {
      $crisp.push(["set", "session:segments", [["financeiro"]]]);
      $crisp.push(["do", "chat:open"]);
      $crisp.push([
        "do",
        "message:send",
        ["text", `Gostei do teste grátis e quero contratar um plano definitivo!`],
      ]);
    },
    getTrialDate() {
      let trialDate = sessionStorage.getItem('trialDate')
      if (trialDate) {
        this.trialDate = trialDate
        this.calcMissingDays()
        return
      }
      serviceTrial.read("")
        .then((resp) => {
          this.trialDate = resp.date
          sessionStorage.setItem('trialDate', resp.date)
          this.calcMissingDays()
        })
        .catch((err) => {console.log(err);});
    },
    calcMissingDays() {
      const createdAtMoment = moment(this.trialDate);
      // Zerar a parte do horário
      createdAtMoment.startOf('day');
      const deadline = createdAtMoment.clone().add(6, 'days').startOf('day');
      const diff = deadline.diff(moment().startOf('day'), 'days');
      this.missingDays = diff;

      if (diff < 0) {
        this.$emit('lastDay');
      }
    },
  },
  computed: {
    trialText() {
      if (this.missingDays) {
        if (this.missingDays <= 0) {
          return `Hoje é o <span>último</span> dia do seu <span>acesso gratuito</span>`
        } 
        if (this.missingDays == 6) {
          return `Desfrute de <span>7 dias</span> de <span>teste gratuito</span>`
        }
        return `Restam <span>${this.missingDays + 1} dias</span> para o término do seu período de <span>teste gratuito</span>`
      }
      return `Resta <span>pouco tempo</span> para o término do seu período de <span>teste gratuito</span>`
    }
  },
  mounted() {
    this.getTrialDate()
  },
};
</script>

<style lang="scss">
.trial-tag {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  z-index: 1002;
  background-color: color-mix(in lch, transparent 80%, var(--orange));
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  svg {
    margin-right: 15px;
    circle {
      fill: var(--orange);
    }
    path {
      stroke: var(--orange);
    }
  }
  span {
    font-size: 15px;
    font-weight: 500;
  }
  > div > span {
    color: var(--gray01);
    > span {
      color: var(--orange) !important;
      font-weight: bolder;
    }
  }
  button {
    background-color: var(--orange);
    color: var(--white);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0 15px;
    border-radius: 10px;
    font-size: 14px;
    color: var(--white);
    font-weight: 600;
    line-height: 1;
    transition: .3s;
    &:hover {
      background-color: var(--orange-dark);
    }
  }
}

.last-day {
  background-color: color-mix(in lch, transparent 80%, var(--red)) !important;
  svg {
    circle {
      fill: var(--red);
    }
    path {
      stroke: var(--red);
    }
  }
  > div > span > span{
    color: var(--red) !important;
  }
  button {
    background-color: var(--red);
    &:hover {
      background-color: var(--red-dark);
    }
  }
}
</style>
