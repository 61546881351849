import middleware from "@/router/middleware";
import authentication from "@/router/middleware/authentication";
import unauthentication from "@/router/middleware/unauthentication";
import redirect from "@/router/middleware/redirect";
import verificDevice from "../middleware/virifcDevice"
// import apiEditor from "@/router/middleware/apiEditor";

export default [
  {
    path: "/",
    name: "Login2",
    component: () => import("../../views/Login.vue"),
    beforeEnter: middleware([redirect]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/",
    name: "LoginRdr",
    component: () => import("../../views/Login.vue"),
    beforeEnter: middleware([redirect]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/login",
    name: "Login",
    component: () => import("../../views/Login.vue"),
    beforeEnter: middleware([unauthentication]),
    meta: {
      sidebar: false,
    },
  },
  // {
  //   path: "/:dynamicRoute/register",
  //   name: "registerDynamic",
  //   component: () => import("../../views/Register.vue"),
  //   beforeEnter: middleware([unauthentication]),
  // },
  {
    path: "/:dynamicRoute/verificar-email",
    name: "verificar-email",
    component: () => import("../../views/VerificarEmail.vue"),
    beforeEnter: middleware([authentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/verificar-email/:token",
    name: "verificar-email-token",
    component: () => import("../../views/VerificarEmail.vue"),
    beforeEnter: middleware([authentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/recuperar-senha",
    name: "Recuperar Senha",
    component: () => import("../../views/RecuperarSenha.vue"),
    beforeEnter: middleware([unauthentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/cancelar-reuniao",
    name: "Cancelar Reunião",
    component: () => import("../../views/CancelarReuniao.vue"),
    beforeEnter: middleware([unauthentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/recuperar-senha/:tk",
    name: "RecuperarSenhaTk",
    component: () => import("../../views/RecuperarSenha.vue"),
    beforeEnter: middleware([unauthentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/token/:integration_token",
    name: "Integração",
    component: () => import("../../views/Integracao.vue"),
    beforeEnter: middleware([unauthentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/token/:integration_token",
    name: "IntegraçãoTk",
    component: () => import("../../views/Integracao.vue"),
    beforeEnter: middleware([unauthentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/home",
    name: "Dashboard",
    component: () => import("../../views/Dashboard.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/selecionar-painel",
    name: "selecionar-painel",
    component: () => import("../../views/SelectPainel.vue"),
    beforeEnter: middleware([authentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/migracao",
    name: "migration",
    component: () => import("../../views/Migration.vue"),
    beforeEnter: middleware([authentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/company",
    name: "Company",
    component: () => import("../../views/Company.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/crm",
    component: () => import("../../views/Pipeline.vue"),
    name: "CRM",
    beforeEnter: middleware([authentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/crm/:id",
    component: () => import("../../views/Pipeline.vue"),
    name: "CRM_id",
    beforeEnter: middleware([authentication]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/estrategico/relatorio-crm",
    name: "relatorio-crm",
    component: () => import("../../components/Pipeline/RelatorioPipeline"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/relatorio-tag/lista",
    name: "relatorio-tag-lista",
    component: () => import("../../views/RelatorioTagLista.vue"),
    beforeEnter: middleware([authentication]),
  }, 
  {
    path: "/:dynamicRoute/estrategico/relatorio-tag/:id?",
    name: "relatorio-tag",
    component: () => import("../../views/RelatorioTag.vue"),
    beforeEnter: middleware([authentication]),
  },  
  {
    path: "/:dynamicRoute/welcome",
    name: "Welcome",
    component: () => import("../../views/Welcome.vue"),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: "/:dynamicRoute/funis",
    component: () => import("../../views/Campanhas.vue"),
    name: "Campanhas",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/funis/email",
    component: () => import("../../views/CampanhasEmail.vue"),
    name: "Eventos do e-mail",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/funis/lista",
    component: () => import("../../views/CampanhasLista.vue"),
    name: "CampanhasLista",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/funis/lista/:id",
    component: () => import("../../views/CampanhasListaUnica.vue"),
    name: "CampanhasListaUnica",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/funis/criar",
    component: () => import("../../views/CampanhasCriar.vue"),
    name: "CampanhasCriar",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/funis/:campaign_id/automacao/:funnel_id",
    component: () => import("../../views/CampanhasEtapas.vue"),
    name: "CampanhasEtapas",
    beforeEnter: middleware([authentication, verificDevice]),
    meta: {
      sidebar: false,
    },
  },
  //
  {
    path: "/:dynamicRoute/estrategico/log-messages",
    component: () => import("../../views/RelatorioLogMessages.vue"),
    name: "LogMessages",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/funis/:campaign_id/criar-formulario",
    component: () => import("../../views/FormularioCriar.vue"),
    name: "CriarFormulario",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/funis/:campaign_id/editar-formulario/:id",
    component: () => import("../../views/FormularioCriar.vue"),
    name: "EditarFormulario",
    beforeEnter: middleware([authentication, verificDevice]),
  },

  {
    path: "/:dynamicRoute/editor",
    name: "TemplaterEditor",
    component: () => import("../../views/Editor.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/editor/:page_id",
    name: "TemplaterEditorPage",
    component: () => import("../../views/Editor.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
    meta: {
      sidebar: false,
    },
  },

  // Editor Campanhas
  {
    path: "/:dynamicRoute/funis/:campaign_id/editor",
    name: "Editor",
    component: () => import("../../views/Editor.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/funis/:campaign_id/editor/:page_id",
    name: "EditorPage",
    component: () => import("../../views/Editor.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/apps/lead-ads",
    name: "Lista-de-fanpages",
    component: () => import("../../views/LeadAd.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/lead-ads/fanpage/:id",
    component: () => import("../../views/LeadAdFanpage.vue"),
    name: "Fanpage",
  },
  {
    path: "/:dynamicRoute/apps/lead-ads/leadgen/",
    component: () => import("../../views/LeadAdHistoric.vue"),
    name: "Historic-integration3",
  },
  {
    path: "/:dynamicRoute/apps/lead-ads/:idFanpage/leadgen/",
    component: () => import("../../views/LeadAdHistoric.vue"),
    name: "Historic-integration2",
  },
  {
    path: "/:dynamicRoute/apps/lead-ads/:idFanpage/leadgen/:idIntegration",
    component: () => import("../../views/LeadAdHistoric.vue"),
    name: "Historic-integration",
  },
  {
    path: "/:dynamicRoute/leads/importacao",
    component: () => import("../../views/ImportacaoLeads.vue"),
    name: "Importacao",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/leads",
    component: () => import("../../views/Leads.vue"),
    name: "Leads",
    beforeEnter: middleware([authentication]),
  },
  // LISTAR FILTROS DA CAMPANHA --->>
  {
    path: "/:dynamicRoute/leads/:campaign_id/:item_id",
    component: () => import("../../views/Leads.vue"),
    name: "LeadsCampaign_id",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/usuarios",
    component: () => import("../../views/Usuarios.vue"),
    name: "Usuarios",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/minha-conta",
    component: () => import("../../views/MinhaConta.vue"),
    name: "MinhaConta",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/lead/:id",
    component: () => import("../../views/LeadUnico.vue"),
    name: "LeadUnico",
    beforeEnter: middleware([verificDevice]),
  },
  {
    path: "/:dynamicRoute/configuracoes",
    component: () => import("../../views/ConfiguracoesSistema.vue"),
    name: "ConfiguracoesSistema",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/midia",
    component: () => import("../../views/Midias.vue"),
    name: "Midia",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/estrategico",
    component: () => import("../../views/Estrategico.vue"),
    name: "estrategico",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/criar-plano/investimento",
    name: "Plano",
    component: () => import("../../views/Simulator.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/editar-plano/investimento/:id",
    name: "PlanoEditar",
    component: () => import("../../views/Simulator.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/criar-plano/lucro",
    name: "Plano2",
    component: () => import("../../views/Simulator2.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/editar-plano/lucro/:id",
    name: "PlanoEditar2",
    component: () => import("../../views/Simulator2.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/trafego/lista",
    name: "Trafego",
    component: () => import("../../views/TrafficList.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/trafego/lista/:id",
    name: "TrafegoVisualizar",
    component: () => import("../../views/TrafficView.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/estrategico/planos/lista",
    name: "PlanoLista",
    component: () => import("../../views/SimulatorList.vue"),
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps",
    component: () => import("../../views/Apps.vue"),
    name: "Apps",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/tags",
    component: () => import("../../views/Tags.vue"),
    name: "Tags",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/integracoes",
    component: () => import("../../views/Integrations.vue"),
    name: "Integrations",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/tags/automacao",
    component: () => import("../../views/TagsAutomacao.vue"),
    name: "TagAutomacao",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/broadcast",
    name: "Broadcast",
    component: () => import("../../views/Broadcast.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/broadcast/lista",
    name: "Lista de Broadcasts",
    component: () => import("../../views/BroadcastListagem.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/broadcast/lista/eventos",
    name: "Lista de Eventos",
    component: () => import("../../views/BroadcastListagemEventos.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/broadcast/wizard",
    name: "Criar Broadcast",
    component: () => import("../../views/BroadcastWizard.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/broadcast/e-mails",
    name: "EmailsBroadcast",
    component: () => import("../../views/BroadcastEmails.vue"),
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/pixel",
    component: () => import("../../views/Pixel.vue"),
    name: "Pixel",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/apps/agendamentos",
    component: () => import("../../views/Agendamentos.vue"),
    name: "Agendamentos",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/agendamentos/criar",
    component: () => import("../../views/AgendamentosCriar.vue"),
    name: "AgendamentosCriar",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/agendamentos/editar/:id",
    component: () => import("../../views/AgendamentosCriar.vue"),
    name: "AgendamentosEditar",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/apps/eventos",
    component: () => import("../../views/Eventos.vue"),
    name: "Eventos",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/templates",
    component: () => import("../../views/ListaTemplates.vue"),
    name: "ListaTemplates",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/dominios",
    component: () => import("../../views/Dominios.vue"),
    name: "Dominios",
    beforeEnter: middleware([authentication, verificDevice]),
  },
  {
    path: "/:dynamicRoute/dominios/dns",
    component: () => import("../../components/Configuracoes/Dominios/CriarDominio.vue"),
    name: "Criar Dominio",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/iframe/:type/:id",
    component: () => import("../../views/Iframe.vue"),
    name: "Iframe",
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/oportunidades",
    component: () => import("../../views/Opportunities.vue"),
    name: "Opportunities",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/oportunidades/:id",
    component: () => import("../../views/Opportunity.vue"),
    name: "Opportunity",
    beforeEnter: middleware([authentication]),
  },
  {
    path: "/:dynamicRoute/IframeLeadList",
    component: () => import("../../views/IframeLeadList.vue"),
    name: "IframeLeadList",
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/iframeLead",
    component: () => import("../../views/IframeLead.vue"),
    name: "IframeLead",
    meta: {
      sidebar: false,
    },
  },
  {
    beforeEnter: middleware([authentication]),
    path: "/:dynamicRoute/AccessDenied",
    component: () => import("../../views/AccessDenied.vue"),
    name: "Acesso negado",
    meta: {
      sidebar: false,
    },
  },
  {
    path: "/:dynamicRoute/:tenant_id/cancelar-inscricao",
    component: () => import("../../views/Inscricao.vue"),
    name: "Desiscrever email",
    meta: {
      sidebar: false,
    },
  },
  {
    path: '/block-route',
    name: 'IsMobile',
    component: () => import('../../views/IsMobile.vue'),
  },
  {
    path: '/sender/success',
    name: 'senderSuccess',
    component: () => import('../../views/SenderSuccess.vue'),
    meta: {
      sidebar: false,
    },
  },
  {
    path: '/sender/failed',
    name: 'senderFailed',
    component: () => import('../../views/SenderFailed.vue'),
    meta: {
      sidebar: false,
    },
  },
  // {
  //   path: "/:dynamicRoute/funil/criar",
  //   component: () => import("../../views/FunilAvancado.vue"),
  //   name: "FunilAvancado",
  //   beforeEnter: middleware([authentication]),
  // },
  {
    path: "/:dynamicRoute/*",
    component: () => import("../../views/404.vue"),
    name: "404",
    meta: {
      sidebar: false,
    },
  },
];
