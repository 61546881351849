<template>
  <BaseModal
    name="distribuition-leads"
    id="distribuition-leads"
    idModal="distribuition-leads"
    size="lg"
    @shown="openModal"
    title="Distribuição de leads"
    help="https://gdigital.crisp.help/pt-br/article/distribuicao-de-leads-19jy8o9/"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Distribui os Leads de forma igualitária.</p>
          </div>
        </div>
        <b-form>
          <b-row>
            <b-col>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  @change="mudaStatus"
                  v-model="equal_distribution_opt"
                  name="is_main"
                  size="lg"
                  switch
                  class="w-100"
                >
                  <p class="info-checkbox mt-2">
                    Ativar distribuição de Leads de forma igualitária?
                  </p>
                </b-form-checkbox>
              </div>
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="change_seller_distribution"
                  name="is_main"
                  size="lg"
                  switch
                  class="w-100"
                >
                  <p class="info-checkbox mt-2">
                    Trocar o vendedor ao se cadastrar novamente?
                  </p>
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-form-group
                label="Vendedor"
                label-for="method"
                class="form-relative"
              >
                <multiselect
                  v-model="equal_distribution_sellers"
                  placeholder="Vendedores"
                  label="email"
                  track-by="id"
                  :options="vendedores"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-no-results="true"
                  :hide-selected="true"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  ><span slot="noResult"
                    >Nenhum vendedor encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>

      <BaseButton
        v-if="!equal_distribution_opt"
        v-b-tooltip.hover
        title="Ative a opção de distribuir leads de forma igualitária para salvar as alterações"
        variant="primary"
      >
        Salvar
      </BaseButton>
      <BaseButton v-else variant="primary" @click="onSubmit">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  components: {
    Multiselect,
  },
  props: ["dadosTag"],
  data() {
    return {
      loading: false,
      equal_distribution_opt: false,
      change_seller_distribution: false,
      equal_distribution_sellers: [],
    };
  },
  computed: {
    vendedores() {
      return this.$store.getters.seller;
    },
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = false;
          var options = [
            "equal_distribution_opt",
            "equal_distribution_sellers",
            "change_seller_distribution",
          ];

          for (let i = 0; i < options.length; i++) {
            const option = options[i];

            var data = {
              meta_key: option,
            };

            if (i === 0) {
              data.meta_value =
                this.equal_distribution_opt === true ? "true" : "false";
            } else if (i === 1) {
              data.meta_value = this.equal_distribution_sellers.length
                ? JSON.stringify(
                    this.equal_distribution_sellers.map(function (item) {
                      return item.id + "";
                    })
                  )
                : "[]";
            } else if (i === 2) {
              data.meta_value =
                this.change_seller_distribution === true ? "true" : "false";
            }

            serviceSettings.create(data).then((response) => {
              if (i === 2) {
                this.$bvModal.hide("distribuition-leads");
              }
            });
          }
        } else {
          this.loading = false;
        }
      });
    },
    fetchOptions() {
      serviceSettings
        .read({ id: `equal-distribution` })
        .then((resp) => {
          for (let i = 0; i < resp.length; i++) {
            const element = resp[i];
            switch (element.meta_key) {
              case "equal_distribution_opt":
                this.equal_distribution_opt =
                  element.meta_value == "true" ? true : false;
                break;
              case "change_seller_distribution":
                this.change_seller_distribution =
                  element.meta_value == "true" ? true : false;
                break;
              case "equal_distribution_sellers":
                var users = [];
                for (
                  let i = 0;
                  i < JSON.parse(element.meta_value).length;
                  i++
                ) {
                  const sellers = JSON.parse(element.meta_value)[i];
                  var result = this.vendedores.find((x) => x.id == sellers);
                  if (result) {
                    users.push(result);
                  }
                }
                this.equal_distribution_sellers = users;
                break;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mudaStatus(e) {
      if (this.equal_distribution_opt) {
        let data = {
          meta_key: "equal_distribution_opt",
          meta_value: "true",
        };
        serviceSettings.create(data);
        let data2 = {
          meta_key: "percentage_distribution_opt",
          meta_value: "false",
        };
        serviceSettings.create(data2);
      } else {
        let data = {
          meta_key: "equal_distribution_opt",
          meta_value: "false",
        };
        serviceSettings.create(data);
      }
    },
    openModal() {
      this.fetchOptions();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

p + p {
  margin-top: 5px;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }

  .top-dados #limpar {
    margin-top: 15px;
  }
}

.form-relative {
  position: relative;
}

.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
