export default {
  getOpportunities: (state) => state.opportunities,
  getPagination: (state) => state.pagination,
  getQuestionsList: (state) => state.questionsList,
  getLosOfGreennSoft: (state) => state.losOfGreennSoft,
  getWinOfGreennSofy: (state) => state.winOfGreennSofy,
  getActiveProft: (state) => state.settingProft.value == 1,
  getOpportunitySelected: (state) => state.opportunitySelected,
  getFilterId: (state) => state.filterOpportunities.id,
  getFilterLeadName: (state) => state.filterOpportunities.leadName,
  getFilterLeadEmail: (state) => state.filterOpportunities.leadEmail,
  getFilterleadPhone: (state) => state.filterOpportunities.leadPhone,
  getFilterOpportunityStatus: (state) => state.filterOpportunities.status,
  getFilterEndDate: (state) => state.filterOpportunities.endDate,
  getFilterStartDate: (state) => state.filterOpportunities.StartDate,
  getFilterOpportunities: (state) => state.filterOpportunities, 
};
