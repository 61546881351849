<template>
  <BaseModal
    name="PendingPayment"
    id="PendingPayment"
    idModal="PendingPayment"
    size="md"
    title="Temos um problema..."
    :hide-footer="true"
    :hideHeader="true"
  >
    <div class="container-flex">
      <svg width="165" height="165" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_6474_362)">
          <circle cx="82.5" cy="82.5" r="52.5" fill="url(#paint0_linear_6474_362)" shape-rendering="crispEdges"/>
        </g>
        <circle cx="82.5" cy="82.5" r="36.5625" stroke="url(#paint1_linear_6474_362)" stroke-width="10"/>
        <path d="M81.9531 78.5806V83.8975M81.9531 89.2144H81.9662M79.7155 71.7484L68.632 90.5436C68.4035 90.9456 68.2825 91.4013 68.2813 91.8655C68.28 92.3297 68.3984 92.7862 68.6247 93.1894C68.851 93.5927 69.1773 93.9288 69.5711 94.1642C69.965 94.3996 70.4127 94.5261 70.8696 94.5312H93.0366C93.4936 94.5261 93.9413 94.3996 94.3351 94.1642C94.729 93.9288 95.0553 93.5927 95.2816 93.1894C95.5079 92.7862 95.6263 92.3297 95.625 91.8655C95.6237 91.4013 95.5028 90.9456 95.2743 90.5436L84.1908 71.7484C83.9575 71.3578 83.629 71.0348 83.2371 70.8107C82.8451 70.5865 82.4029 70.4688 81.9531 70.4688C81.5033 70.4688 81.0611 70.5865 80.6692 70.8107C80.2772 71.0348 79.9488 71.3578 79.7155 71.7484Z" style="stroke: var(--orange);" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
          <filter id="filter0_d_6474_362" x="0" y="0" width="165" height="165" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="15"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.752941 0 0 0 0 0.376471 0 0 0 0.5 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6474_362"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6474_362" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_6474_362" x1="130.5" y1="119.625" x2="-37.5" y2="-8.24999" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFD89B"/>
            <stop offset="1" stop-color="#FFD89B" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_6474_362" x1="120.5" y1="111.891" x2="-12.5" y2="10.6563" gradientUnits="userSpaceOnUse">
            <stop style="stop-color: var(--orange-light);"/>
            <stop offset="1" stop-color="#FFD89B" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
      <span>Seu pagamento está pendente! 🥲</span>
      <p>Um de seus contratos está com o pagamento atrasado. Por favor, entre em contato com nosso time financeiro.</p>
      <div style="display: flex; gap: 15px;">
        <BaseButton variant="link-info" @click="closeModal">
          Lembrar depois
        </BaseButton>
        <BaseButton variant="primary" @click="hireResource">
          Entrar em contato
        </BaseButton>
      </div>
    </div>

    <template v-slot:footer="{}">
    </template>
  </BaseModal>
</template>

<script>
export default {
  data() {
    return {
      recurso: 0,
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("PendingPayment");
    },
    hireResource() {
      $crisp.push(["set", "session:segments", [["financeiro"]]]);
      $crisp.push(["do", "chat:open"]);
      $crisp.push([
        "do",
        "message:send",
        ["text", `Preciso resolver meus problemas financeiros.`],
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-flex {
  display: grid;
  justify-items: center;
  text-align: center;
  margin-bottom: 30px;
  p {
    color: var(--gray03);
    font-size: 14px;
    max-width: 85%;
    margin: 15px 0 30px;
  }
  span {
    font-weight: 600;
    font-size: 20px;
  }
}
</style>
