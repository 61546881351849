const state = {
  isGreenn: false,
  nameSystem: '',
};

const getters = {
  getIsGreenn: currentState => currentState.isGreenn,
  getNameSystem: currentState => currentState.nameSystem,
};

const mutations = {
  setIsGreenn: (state, value) => state.isGreenn = value,
  setNameSystem: (state, value) => state.nameSystem = value,
};

const actions = {
  updateIsGreenn: ({commit}, data) =>  commit('setIsGreenn', data),
  updateNameSystem: ({commit}, data) =>  commit('setNameSystem', data),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
