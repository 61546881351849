import OpportunitiesService from "@/services/resources/OpportunitiesService";
const opportunitiesService = OpportunitiesService.build();

import ExportOpportunitiesService from "@/services/resources/ExportOpportunitiesService";
const exportOpportunitiesService = ExportOpportunitiesService.build();


import DashboardService from "@/services/resources/DashboardService";
const dashboardService = DashboardService.build();

import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceUserSettings = UserSettingsService.build();

export default {
  async getOpportunities({ commit, getters }, pagination = { perPage: 10, page: 1 }) {
    let stringQuery = `list?page=${pagination.page}&perPage=${pagination.perPage}`
    const objectFilters = getters['getFilterOpportunities']

    for (const key in objectFilters) {
      if (objectFilters[key] !== null) {
        stringQuery += `&${key}=${encodeURIComponent(objectFilters[key])}`;
      }
    }
    try {
      const { messages } = await opportunitiesService.get(stringQuery);
      commit("setDataOpportunities", messages.data);
      commit("setTotal", messages.total);
      return messages;
    } catch (error) {
      console.error(error);
    }
  },

  async updateItemsPerPage({ commit, dispatch }, pagination) {
    commit("setCurrentPage", 1);
    commit("setPerPage", pagination.perPage);
    dispatch("getOpportunities", pagination);
  },

  async updateCurrentPag({ commit, dispatch }, pagination) {
    commit("setCurrentPage", pagination.page);
    dispatch("getOpportunities", pagination);
  },

  async setPageOne({ commit }){
    commit("setCurrentPage", 1);
  },

  async getLossOfGreennSofyValue({ commit }) {
    try {
      const data = await dashboardService.get("/potential-purchase");
      if (data.messages.potential) {
        commit("setLosOfGreennSoft", data.messages.potential);
        return data.messages.potential;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },

  async getWinOfGreennSofyValue({ commit }) {
    try {
      const data = await opportunitiesService.get("/converted-amount");
      if (data.messages.amount) {
        commit("setWinOfGreennSofy", data.messages.amount);
        return data.messages.amount;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },

  async getProftSettings({ commit }, id) {
    const key = "PROFIT_ACTIVATED";
    try {
      const data = await serviceUserSettings.search({ url: id, filter: [key] });
      const dataProft = data.find((item) => item.key == key);

      if (dataProft) {
        commit("setSettingProft", dataProft);
        return dataProft;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },

  async findUniqueOpportunity({ commit }, id) {
    try {
      const data = await opportunitiesService.read(`/${id}`);
      commit("setOpportunitySelected", data?.messages);
    } catch (error) {
      console.error(error);
    }
  },

  async exportOpportunity({ getters }, data) {
    try {
      const objectFilters = getters['getFilterOpportunities']

      for (const key in objectFilters) {
        if (objectFilters[key] !== null) {
          data[key] = objectFilters[key];
        }
      }

      await exportOpportunitiesService.create(data);
    } catch (error) {
      console.error(error);
    }
  },

  async setOpportunityStatus({ dispatch, commit }, status) {
    commit('setFilterOpportunityStatus', status);
    await dispatch('setPageOne');
    await dispatch('getOpportunities');
  },

  async setNewValueInFilter({ commit },{ mutationName , newValue }){
    if(!mutationName) throw new Error('Informe o nome da mutation no objeto como mutationName')
    const valueClear = newValue.replace(/\s+/g, '')
    const value = valueClear !== '' ? newValue : null
    commit(mutationName, value)
  },

  async updateStatusOpportunity({ commit }, payload) {
    const { status, id } = payload

    try {
      await opportunitiesService.patch(`/${id}`, { status });

      commit('setNewStatusOpportunity', status);
    } catch (error) {
      console.error(error);
    }
  },

  setclearFilter({ commit }){
    commit('setFilterId', null);
    commit('setFilterName', null);
    commit('setFilterLeadName', null);
    commit('setFilterLeadEmail', null);
    commit('setFilterleadPhone', null);
    commit('setFilterOpportunityStatus', null);
  }
};
