<template>
  <div
    class="menu-links"
    :class="{ 'ajust-itens': $store.getters.setPanel }"
    :style="level === 'templater' ? 'height:200px' : ''"
  >
    <div class="menu-actions">
      <img
        :src="require('@/assets/img/G-digital.png')"
        class="logo"
        @click="redirectHome"
      />

      <img
        v-if="$store.getters.user.user.level == 'owner' && can_integrate"
        @click="$router.push({ name: 'Company' })"
        src="@/assets/img/icons/apps-svgrepo-com.svg"
        alt="dots"
        style="cursor: pointer; position: relative; top: -2px; left: 20px"
        v-b-tooltip.hover
        title="Greenn Company Apps"
      />
    </div>
    <PendingPayment title="PendingPayment"> </PendingPayment>

    <router-link
      :is="$route.path === `/${dynamicRoute}/home` ? 'a' : 'router-link'"
      :class="{
        'router-link-exact-active router-link-active':
          $route.path === `/${dynamicRoute}/home`,
      }"
      to="/dynamicRoute/home"
      v-if="!$store.getters.setPanel || ($store.getters.setPanel && dashboard)"
      ><img src="@/assets/img/icons/dashboard.svg" />Home
    </router-link>

    <router-link
      :is="$route.path === `/${dynamicRoute}/estrategico` ? 'a' : 'router-link'"
      :class="{
        'router-link-exact-active router-link-active':
          $route.path === `/${dynamicRoute}/estrategico`,
        'router-link-exact-active router-link-active':
          $route.path.includes('estrategico'),
      }"
      :to="$store.getters.recursos.nstrategic ? '/dynamicRoute/estrategico' : '/dynamicRoute/AccessDenied'"
      v-if="level !== 'templater' && level != 'blogger' && level !== 'seller'"
    >
      <img src="@/assets/img/icons/plans.svg" />
      Estratégico
      <svg v-if="!$store.getters.recursos.nstrategic" fill="#333" style="margin-left: 10px;" height="16px" width="16px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
    </router-link>

    <router-link
      :is="$route.path === `/${dynamicRoute}/leads` ? 'a' : 'router-link'"
      :class="{
        'router-link-exact-active router-link-active':
          $route.path === `/${dynamicRoute}/leads`,
        'router-link-exact-active router-link-active':
          $route.path.includes('lead') && !$route.path.includes('apps'),
      }"
      v-if="!$store.getters.setPanel && validLevel('leads')"
      to="/dynamicRoute/leads"
      ><img src="@/assets/img/icons/affiliate.svg" />Leads
    </router-link>

    <router-link
      :is="$route.path === `/${dynamicRoute}/templates` ? 'a' : 'router-link'"
      :class="{
        'router-link-exact-active router-link-active':
          $route.path === `/${dynamicRoute}/templates`,
      }"
      to="/dynamicRoute/templates"
      v-if="level == 'templater'"
    >
      <img src="@/assets/img/icons/pages.svg" />
      Templates
    </router-link>

    <router-link
      :is="$route.path === `/${dynamicRoute}/funis/lista` ? 'a' : 'router-link'"
      :to="'/dynamicRoute/funis/lista'"
      v-if="
        level !== 'templater' && level !== 'seller' && level !== 'gerent_seller'
      "
      :class="{
        'router-link-exact-active router-link-active':
          $route.path === `/${dynamicRoute}/funis/lista`,
        'router-link-exact-active router-link-active':
          $route.path.includes('funis'),
      }"
    >
      <img src="@/assets/img/icons/filtro_menu.svg" />Funis
    </router-link>
    <router-link
      :to="$store.getters.recursos.ncrm ? `/${dynamicRoute}/crm` : `/${dynamicRoute}/AccessDenied`"
      v-if="
        level !== 'templater' &&
        level !== 'blogger'
      "
      :style="$store.getters.recursos.ncrm ? '' : 'cursor: not-allowed'"
      target="_blank"
    >
      <img src="@/assets/img/icons/my-sales.svg" /> CRM<svg v-if="!$store.getters.recursos.ncrm" fill="#333" style="margin-left: 10px;" height="16px" width="16px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg> 
    </router-link>
    <router-link
      :is="$route.path === `/${dynamicRoute}/apps` ? 'a' : 'router-link'"
      :class="{
        'router-link-exact-active router-link-active':
          $route.path === `/${dynamicRoute}/apps`,
        'router-link-exact-active router-link-active':
          $route.path.includes('apps'),
      }"
      :to="'/dynamicRoute/apps'"
      v-if="level !== 'templater' && level != 'blogger'"
    >
      <img src="@/assets/img/icons/apps.svg" />Aplicativos
    </router-link>

    <!-- <a @click="contract_resource">
      <img src="@/assets/img/icons/shop.svg" /> Recursos
    </a> -->

    <a
      href="https://greenn.crisp.help/pt-br/"
      target="_blank"
      v-if="level !== 'templater'"
    >
      <img src="@/assets/img/icons/help-circle1.svg" /> Ajuda
    </a>

    <a class="box-notificacoes" v-show="hasUpdatedToken">
      <img src="@/assets/img/icons/notfy.svg" /> Notificações
    </a>

    <DistribuicaoLeads />
    <DistribuicaoLeadsPorcentagem />
    <Logout :isBasic="isBasic" />
  </div>
</template>

<script>
import Vue from "vue";
import Cookies from "js-cookie";
import ResourceCount from "@/services/resources/RecursesService";
const resourceCount = ResourceCount.build();
import RedirectService from "@/services/resources/RedirectService";
const service = RedirectService.build();
import DistribuicaoLeads from "@/views/DistribuicaoLeads";
import DistribuicaoLeadsPorcentagem from "@/views/DistribuicaoLeadsPorcentagem";
import ClubService from "@/services/resources/ClubService.js";
const serviceClub = ClubService.build();

import EqualService from "@/services/resources/EqualService.js";
const serviceEqual = EqualService.build();

import UserResourcesService from "@/services/resources/UserResourcesService";
const serviceResources = UserResourcesService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import contractPlano from "../../services/resources/contractPlan";
const contractPlan = contractPlano.build();

import PendingPayment from "@/components/Apps/ProductsIntegration/PendingPayment";


import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();

import { jwtDecode } from 'jwt-decode';


import Logout from "./Logout.vue";
export default {
  props: ['isBasic'],
  components: {
    DistribuicaoLeads,
    DistribuicaoLeadsPorcentagem,
    PendingPayment,
    Logout,
  },
  data() {
    return {
      exist_notfy: false,
      sellerConfig: "",
      item: null,
      contract:[],
      dashboard: false,
      vendas: false,
      cupom: false,
      recursos: this.$store.getters.recursos,
      level: this.$store.getters.user.user.level,
      equal: null,
      config_tag: "",
      can_integrate: false
    };
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub;
    },
    hasUpdatedToken(){
      var token = Cookies.get("access_token");
      const decodedToken = jwtDecode(token);
      if(!decodedToken || (decodedToken && !decodedToken.tenant_id && ! decodedToken.user_id)){
        return false;
      }
      return true;
    },
    dynamicRoute(){
      return this.$route.params.dynamicRoute
    }
  },
  methods: {
    redirectHome() {
      this.$router.push({ name: "Dashboard" });
    },
    canIntegrate(){
      serviceGlobalSettings
        .read({id: "company_users"})
        .then((resp) => {
          let users = JSON.parse(resp.meta_value) ?? [];
          this.can_integrate = users.includes(this.$store.getters.user.user.id)
        }).catch(() => {
          this.can_integrate = false
        })
    },
    contract_resource() {
      contractPlan
        .search()
        .then((r) => {
          let name = `${r.first_name} ${r.last_name}`;
          let phone = r.phone;
          let email = r.email;
          let document = r.document;
          let url = `https://gdigital.com.br/g-shopping/?fn=${name}&ph=${phone}&em=${email}&document=${document}&force=true`;
          window.open(url, "_blank");
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    fetchConfigSeller() {
      var data = {
        id: "seller",
      };
      serviceSettings.read(data).then((resp) => {
        for (let i = 0; i < resp.length; i++) {
          const seller = resp[i];
          if (seller.meta_key === "change_tag") {
            this.config_tag = seller.meta_value;
          }
        }
      });
    },
    validLevel(param) {
      if (
        param === "leads" &&
        this.level !== "blogger" &&
        this.level !== "templater"
      ) {
        return true;
      }
    },
    async updateResources() {
      let hasAppeard = Cookies.get("modalShown")
      this.contract = JSON.parse(sessionStorage.getItem("userContracts"));
      if(!this.contract || !this.contract.length){
        await resourceCount.read("/contrato").then((respp) => {
            this.contract = respp;
            sessionStorage.setItem("userContracts",JSON.stringify(respp));
          });
      }

      serviceResources.search().then((resp) => {
        if (!Object.keys(resp).length && this.contract && !(this.contract.filter(cont => cont.produto_id >= 118).length === 1 && this.contract.find(cont => cont.produto_id === 132)) && !hasAppeard) {
          this.$bvModal.show("PendingPayment");
          Cookies.set("modalShown", "true", { expires: 0.5 });
        }
        localStorage.setItem("resources", JSON.stringify(resp));
      });
      if(this.contract){
        if (this.contract.find(item => (item.status === 1 || item.status === 0 || item.status === 2 || item.status === -5) && item.produto_id >= 118)) {
          if (this.contract.find(item => item.status == 2 && item.produto_id >= 118) && !hasAppeard) {
            this.$bvModal.show("PendingPayment");
            Cookies.set("modalShown", "true", { expires: 0.5 });
          }
          return
        }
        if (this.contract.find(item => item.produto_id === 132)) {
          return
        }
      }

      this.$store.dispatch("logoutRequest");
      this.$bvModal.show("ValidatePlan");
      Cookies.remove("access_token");
    },
    future() {
      serviceClub.search({ id: "site_id=53" }).then((resp) => {
        if (resp.error == "unauthorized") {
          this.$grToast.toast("E-mail não verificado", {
            title: "Erro",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        } else {
          window.open(resp.site + "?rdr=" + resp.string, "_blank");
        }
      });
    },
    openDistribuicao() {
      this.$bvModal.show("distribuition-leads");
    },
    openDistribuicaoPorcent() {
      this.$bvModal.show("distribuition-leads-porcentagem");
    },
    openLastG(project) {
      service.create({}).then((resp) => {
        let url =
          "https://" +
          resp.domain +
          "/gadmin/?rdrk=" +
          resp.ck +
          "#/" +
          project;

        window.open(url, "_blank");
      });
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "DashboardRead")) {
            this.dashboard = true;
          }
          if (dados.find((x) => x.name === "SaleRead")) {
            this.vendas = true;
          }
          if (dados.find((x) => x.name === "CouponRead")) {
            this.cupom = true;
          }
        }
      }
    },

    equalTest() {
      serviceEqual
        .search()
        .then((response) => {
          this.equal = response.status;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getPermissions();
    this.$root.$on("reset-all", (data) => {
      this.getPermissions();
    });
    this.$root.$on("reset-permissions", (data) => {
      this.getPermissions();
    });
    this.canIntegrate();
    this.updateResources();

    // this.equalTest();
    // this.fetchConfigSeller()
    // this.$bvModal.show("ValidatePlan");
    this.$root.$on("globalNotfy", (data) => {
      this.exist_notfy = data;
    });
  },
  watch: {
    '$route': async function(newRoute, oldRoute) {
      await this.updateResources();
    },
  }
};
</script>

<style scoped>
.menu-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.logo {
  width: 40px;
  cursor: pointer;
  margin: 25px -7px 40px -7px;
}
.ajust-itens {
  height: 15vh !important;
}
.menu-links {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: flex-start;
  /* justify-content: space-between; */
  width: 100%;
  padding: 0 !important;
}
.menu-links a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 230px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: var(--gray01) !important;
  font-weight: 600 !important;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
  padding-bottom: 40px;
}
.menu-links a:hover,
.menu-links a img:hover {
  text-decoration: none;
  color: var(--greenn);
}
.menu-links a img {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 41px;
  max-width: 22px;
}
.menu-links a.router-link-exact-active img,
.menu-links a:hover img {
  filter: invert(0);
  /* Caso seja gradient */
  /* filter: brightness(0%); */
}
.menu-links a.router-link-exact-active,
.menu-links a:hover {
  color: var(--greenn) !important;
  /* Caso seja gradient */
  /* color: var(--gray01) !important; */
}

.menu-links a.router-link-exact-active::before {
  content: "";
  width: 7px;
  height: 7px;
  background: var(--greenn);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  transition: all 0.3s ease;
}

/* grandiente */
/* .menu-links a.router-link-exact-active::before {
  content: "";
  width: 6px;
  height: 0;
  background: linear-gradient(180deg, var(--greenn) 40%, rgba(5, 138, 42, 0) 100%);
  display: block;
  position: absolute;
  left: -40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 0px 10px 10px 0;
  transition: all 0.5s;
  animation: growHeight 0.8s ease forwards;
}
@keyframes growHeight {
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    height: 60px;
    opacity: 1;
    transform: translateY(0);
  }
} */
img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}
/* Sub menu */
.sub-menu {
  width: 250px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 200px;
  /* top: 200px; */
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_02 {
  width: 280px;
}
#sub_03 {
  width: 333px;
}

.sub-menu li a {
  font-weight: 600;
  color: var(--gray01) !important;
  font-size: 14px;
  padding: 10px;

  /* background: silver; */
}
.sub-menu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
/* .Menu:hover  */
.menu-links a {
  color: var(--gray01);
}

/* --- */
.sub-menu2 {
  top: 300px;
}
.sub-menu3 {
  top: 300px;
}
.rotateArrow {
  transform: rotate(-90deg);
}

.logo-club {
  max-width: 115px !important;
  width: 115px;
  margin-left: -3px !important;
}
</style>
