var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-logout"},[_c('div',{staticClass:"logout-btn"},[_c('router-link',{staticClass:"valid",attrs:{"to":{ name: 'MinhaConta' }}},[(_vm.foto_usuario && _vm.render)?_c('img',{staticClass:"foto-usuario valid",class:{ contains: _vm.foto_usuario },attrs:{"src":_vm.changeUrl(_vm.foto_usuario)}}):_c('img',{staticClass:"foto-usuario valid",attrs:{"src":require("@/assets/icons/picture-not-user.svg"),"alt":"picture"}})]),_c('div',{staticClass:"space-flex valid"},[_c('div',{staticClass:"infos-user valid"},[_c('p',{staticClass:"valid"},[_vm._v(_vm._s(_vm.userName()))])]),_c('a',{staticClass:"valid",on:{"click":_vm.menuConfig}},[_c('img',{staticClass:"item-config valid",attrs:{"src":require("@/assets/img/icons/config.svg")}})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.sub_menu),expression:"sub_menu"}],staticClass:"sub-menu valid",attrs:{"id":"sub_config"},on:{"mouseleave":function($event){_vm.sub_menu = false}}},[_c('li',{staticClass:"valid",on:{"click":function($event){_vm.sub_menu = false}}},[(_vm.level !== 'templater')?_c(_vm.$route.path === `/${_vm.dynamicRoute}/minha-conta`
                ? 'a'
                : 'router-link',{tag:"router-link",staticClass:"valid",class:{
              'router-link-exact-active': _vm.$route.path.includes('minha-conta'),
            },attrs:{"to":"/dynamicRoute/minha-conta"}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/user-config.svg")}}),_vm._v(" Meus dados ")]):_vm._e(),(_vm.level == 'owner' || _vm.level == 'admin')?_c(_vm.$route.path === `/${_vm.dynamicRoute}/dominios`
                ? 'a'
                : 'router-link',{tag:"router-link",staticClass:"valid",class:{
              'router-link-exact-active': _vm.$route.path.includes('dominios'),
            },attrs:{"to":"/dynamicRoute/dominios"}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/sites.svg")}}),_vm._v(" Domínios ")]):_vm._e(),(
              _vm.level == 'owner' ||
              _vm.level == 'admin' ||
              _vm.level == 'commercial_leader'
            )?_c(_vm.$route.path === `/${_vm.dynamicRoute}/configuracoes`
                ? 'a'
                : 'router-link',{tag:"router-link",staticClass:"valid",class:{
              'router-link-exact-active':
                _vm.$route.path.includes('configuracoes'),
            },attrs:{"to":"/dynamicRoute/configuracoes"}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/company-config.svg")}}),_vm._v(" Configurações do sistema ")]):_vm._e(),( _vm.level == 'owner' ||
              _vm.level == 'admin' ||
              _vm.level == 'commercial_leader'
            )?_c(_vm.$route.path === `/${_vm.dynamicRoute}/usuarios`
                ? 'a'
                : 'router-link',{tag:"router-link",staticClass:"valid",class:{
              'router-link-exact-active': _vm.$route.path.includes('usuarios'),
            },attrs:{"to":"/dynamicRoute/usuarios"}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/users.svg")}}),_vm._v(" Usuários ")]):_vm._e(),(_vm.painel && _vm.painel.length > 1)?_c(_vm.$route.path === `/${_vm.dynamicRoute}/selecionar-painel`
                ? 'a'
                : 'router-link',{tag:"router-link",staticClass:"valid",class:{
              'router-link-exact-active':
                _vm.$route.path.includes('selecionar-painel'),
            },attrs:{"to":"/dynamicRoute/selecionar-painel"}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/repeat.svg")}}),_vm._v(" Alterar Painel ")]):_vm._e(),(_vm.canImport())?_c('router-link',{staticClass:"valid",class:{
              'router-link-exact-active': _vm.$route.path.includes('migracao'),
            },attrs:{"to":"/dynamicRoute/migracao"}},[_c('img',{staticClass:"valid mb-1",attrs:{"src":require("@/assets/img/icons/download.svg")}}),_vm._v(" Migrar Dados ")]):_vm._e(),(_vm.level !== 'seller' && _vm.level !== 'gerent_seller')?_c(_vm.$route.path === `/${_vm.dynamicRoute}/midia` ? 'a' : 'router-link',{tag:"router-link",staticClass:"valid",class:{
              'router-link-exact-active': _vm.$route.path.includes('midia'),
            },attrs:{"to":"/dynamicRoute/midia"}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/midia.svg")}}),_vm._v(" Mídia ")]):_vm._e(),(!_vm.isBasic)?_c('a',{staticClass:"valid",on:{"click":_vm.contract_resource}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/shop.svg")}}),_vm._v(" Comprar Extras ")]):_vm._e(),_c('a',{staticClass:"valid",attrs:{"target":"_blank","href":_vm.termsURL}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/termos.svg")}}),_vm._v(" Termos de uso ")]),_c('a',{staticClass:"valid",attrs:{"href":"#","id":"logout-btn"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('img',{staticClass:"valid",attrs:{"src":require("@/assets/img/icons/sign-out.svg")}}),_vm._v("Finalizar sessão")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }