<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import Cookies from "js-cookie";

const props = defineProps({
  name: {
    type: String,
    default: "Defina o nome a ser exibido",
  },
  resource: {
    type: Boolean,
    default: false,
  },
  route: {
    type: String,
    default: "Home",
  },
  active: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  traduction: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click"]);

const translateName = computed(() => {
  return props.name;
});

const dynamicRoute = ref(Cookies.get("tenantSubdomain") || "adm");

function emitClick() {
  emit("click", props.name);
}
</script>
<template>
  <div class="item" @click="emitClick">
    <router-link :class="{ active: props.active }" :to="'/' + dynamicRoute + '/'+ (props.resource ? props.route : 'AccessDenied')"
>
      <slot />
        <h1 :class="{ hover: props.hover }" style="display: flex;align-items: center;">{{ translateName }}
          <svg v-if="!props.resource" fill="#999E9D" height="16px" width="16px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
        </h1>
    </router-link>
  </div>
</template>


<style scoped lang="scss">
.item {
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--spacing-6);
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    padding: var(--spacing-4);
    transition: var(--transition);
    border-radius: var(--radius-lg);
    height: 48.19px;
    min-width: 48.19px;
    color: var(--gray-300);
    :visited {
      color: inherit;
    }

    &:focus-visible {
      transition: none;
      outline: 1.5px solid var(--primary-500);
    }

    &:hover:not(.active) {
      background: var(--gray-10) !important;
    }

    h1 {
      color: var(--gray-300);
      font-size: var(--font-md);
      font-weight: var(--weight-medium);
      overflow: hidden;
      text-overflow: ellipsis;

      &.hover {
        animation: fadeEnter 1s ease;
      }

      &:not(.hover) {
        animation: fadeExit 1s ease;
      }

      @media (max-width: 768px) {
        animation: none !important;
      }
    }

    &.active {
      background-color: var(--primary-50);
      h1 {
        color: var(--primary-500);
        font-weight: var(--weight-semibold);
      }
    }
  }
  transition: var(--transition);
}

@keyframes fadeEnter {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadeExit {
  0% {
    width: 100%;
  }
  70% {
    width: 0;
  }

  100% {
    opacity: 0;
  }
}
</style>
