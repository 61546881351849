<script setup>
import { inject } from "vue";

const dropdownSubOpen = inject("dropdownSubOpen");

if (!dropdownSubOpen) {
  throw new Error("dropdownSubOpen not provided");
}
</script>

<template>
  <div
    :class="{ active: dropdownSubOpen }"
    class="gr-drop-sub-item-content"
    tabindex="0"
  >
    <div class="gr-drop-sub-item">
      <slot />
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="var(--gray-200)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped lang="scss">
.gr-drop-sub-item-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  background: transparent;
  cursor: pointer;
  border-radius: var(--radius-md);
  padding: var(--spacing-1-5) var(--spacing-2) var(--spacing-1-5)
    var(--spacing-2);
  width: var(--w-full);
  transition: var(--transition);
  justify-content: space-between;
  position: relative;

  &.active {
    background: rgb(229, 231, 231, 0.35);
  }

  &:focus-visible {
    transition: none;
    outline: 0px solid transparent;
    background: rgb(229, 231, 231, 0.35);
  }

  &:hover {
    background: rgb(229, 231, 231, 0.35);
  }

  svg {
    transform: rotate(270deg);
    width: var(--w-3);
  }

  .gr-drop-sub-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-3);
  }
}
</style>
