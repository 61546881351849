import store from "@/store/index";
import Cookies from "js-cookie";

function isAuthenticated(to, from, next) {
  const dynamicRoute = Cookies.get("tenantSubdomain") || "adm";
  const requestedDynamicRoute = to.params.dynamicRoute;
  const resultRoute = to.fullPath.replace('/'+requestedDynamicRoute, '');

  // console.log(`totototo`, to, from, next);

  // console.log('resultRoute', resultRoute);

  const params = new URLSearchParams(window.location.search);


  if (store.getters.isAuthenticated && !store.getters.verifyEmail && !params.has('rdrk')) {
    return next(`/adm/verificar-email${window.location.search}`);
  }

  if (store.getters.isAuthenticated && !params.has('rdrk')) {
      return next(`/${dynamicRoute}/selecionar-painel${window.location.search}`)
  }

  // Executa seu middleware personalizado antes da verificação do cookie e redirecionamento
  if (dynamicRoute !== requestedDynamicRoute) {
    next(`/${dynamicRoute}${resultRoute}`);
  } else {
    next();
  }

  return next();
}

const unauthentication = isAuthenticated;

export default unauthentication;
