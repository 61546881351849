import ProductQuestion from "@/services/resources/ProductQuestion";
const serviceQuestion = ProductQuestion.build();

export default {
  setProductId: ({ commit }, productId) => {
    commit("upDateProductIdMakeQustion", productId);
  },
  setQuestionId: ({ commit }, questionId) => {
    commit("upDateQuestionIdMakeQustion", questionId);
  },
  setTextQuestion: ({ commit }, text) => {
    commit("upDateTextQuestion", text);
  },
  setTextResponse: ({ commit }, text) => {
    commit("upDateTextResponse", text);
  },
  validMakeQuestion: ({ commit, state }) => {
    let textQuestions = state.makeQuestion.question.replace(/\s/g, "").length;
    let textAnswers = state.makeQuestion.response.replace(/\s/g, "").length;

    const hasQuestion = textQuestions >= 5 && textQuestions <= 200;
    let hasResponse;

    if(!textAnswers){
      hasResponse = true;
    }else{
      hasResponse = textAnswers >= 2 && textAnswers <= 600;
    }
    
    const requireds = {
      products: false,
      question: false,
      response: false,
    };

    if (!hasQuestion) requireds.question = true;
    if (!hasResponse) requireds.response = true;

    return requireds;
  },
  setPostQuestion: async ({ commit, state }) => {
    const data = {
      productId: state.makeQuestion.productId, 
      question: state.makeQuestion.question, 
      answer:state.makeQuestion.response
    };
    try {
      const response = await serviceQuestion.create(data);
      
      if(response.status == "SUCCESS"){
        commit('opportunities/setNewQuestion', data, { root: true });
        return true
      } 

      return false;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  setPutQuestion: async ({ commit, state }) => {
    const data = {
      id: `${state.makeQuestion.questionId}`,
      productId: state.makeQuestion.productId, 
      question: state.makeQuestion.question, 
      answer:state.makeQuestion.response
    };
    try {
      const response = await serviceQuestion.update(data);
      
      if(response.status == "SUCCESS") return true

      return false;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  setClearQuestions: ({ commit }) => {
    commit("upDateTextQuestion", "");
    commit("upDateTextResponse", "");
  }
};
