import Api from "@/services/API";

/**
 * @typedef {AuthService}
 */

export default class Auth extends Api {
  /**
   * @type {String}
   */
  static base = process.env.VUE_APP_API_HOST;
  /**
   * @type {String}
   */
  static resource = "/user/change-tenant";

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor(resource, options = {}, http = null) {
    super(Auth.normalize(Auth.base, resource), options, http);
  }

  /**
   * @return {this}
   */
  static build() {
    return new this(this.resource);
  }

  login(username, authorize, state, redirect_uri ) {
    let data = {
      subdomain: username,
      authorize: authorize,
      state: state,
      redirect_uri: redirect_uri,
    };

    return this.post("", data);
  }
}
