import Rest from '@/services/Rest';

/**
 * @typedef {EqualService}
 */
export default class EqualService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'utils/equal_distribuition'
}