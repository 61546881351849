import axios from 'axios';
import Cookies from 'js-cookie';
import store from '@/store/index'
import router from '@/router/index'

import {
  EventBus
} from '@/main.js'

const standard = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 100000,
  transformResponse: [
    function (data) {
      return data;
    },
  ],
});

standard.interceptors.response.use(function (response) {
  return response;
}, function (err) {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        store.dispatch("logoutRequest").catch(function (err) {});
        router.push('/')
        EventBus.$emit('errorNotify', '401 - Usuário não autorizado. Refaça o login e tente novamente')
        break
    }
  } else {
    EventBus.$emit('errorNotify', 'Erro ao executar a requisição')
  }

  return Promise.reject(err);
});

if(Cookies.get('access_token')){
  standard.defaults.headers.common['Authorization'] = Cookies.get('access_token');
}

//standard.defaults.headers.common['Access-Control-Allow-Headers'] = 'Set-Cookie'

// standard.interceptors.response.use(..., ...)

export default standard;
