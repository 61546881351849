const requireModule = require.context(
  '.', // A pasta atual
  true, // Incluir subdiretórios
  /index\.js$/ // Padrão de arquivo para encontrar (somente index.js)
);

const modules = {};

requireModule.keys().forEach((fileName) => {
  // Pega o nome do módulo (o nome da pasta)
  const moduleName = fileName.replace(/^\.\/(.*)\/index\.js$/, '$1');

  // Ignora o aquivo de exportação dinamica 
  if(moduleName === './index.js') return;
  
  // Carrega o conteúdo do módulo
  const moduleConfig = requireModule(fileName).default;
  
  // Define o módulo no objeto de módulos, com namespaced
  modules[moduleName] = {
    ...moduleConfig,
  };
});

export default modules;