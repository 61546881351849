/**
 * This method opens a modal through the vue instance
 * @param {string} modal modal name
 * @param {Vue} vm vue
 */
const show = (modal, vm) => {
    if(!(Object.keys(vm.$refs[modal].$refs)).length){
        vm.$refs[modal].show();
        return;
    }
    vm.$refs[modal].$refs[modal].shown();
}

/**
 * This method hidden a modal through the vue instance
 * @param {string} modal modal name
 * @param {Vue} vm vue
 */
const hide = (modal, vm) => {
    if(!(Object.keys(vm.$refs[modal].$refs)).length){
        vm.$refs[modal].hidden();

        return;
    }
    vm.$refs[modal].$refs[modal].hidden();
}

export { show, hide }