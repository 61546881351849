export default {
  user: (state) => state.user,
  blog: (state) => state.blog,
  blogs: (state) => state.blogs,
  forms: (state) => state.forms,
  pages: (state) => state.pages,
  seller: (state) => state.seller,
  dashboard: (state) => state.dashboard,
  tags: (state) => state.tags,
  recursos: (state) => state.resources,
  hasResources: (state) => (param) => state.resources[param] != 0,
  getResources: (state) => (param) => state.resources[param],
  verifyEmail: state => state.verifyEmail,
  tenantSubdomain: state => state.tenantSubdomain,
};
