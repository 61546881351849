import Rest from "@/services/Rest";

/**
 * @typedef {SettingsService}
 */
export default class SettingsService extends Rest {
  /**
   * @type {String}
   */
  static resource = "settings";
}
