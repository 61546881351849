<template >
  <b-modal
    :id="name"
    :size="size"
    :title="title"
    :hide-footer="hideFooter"
    :hide-header="hideHeader"
    :no-close-on-backdrop="noBackdropClose"
    :no-close-on-esc="noEscClose"
    @shown="shown"
    @hidden="hidden"
    centered
  >
    <template #modal-title>
      <div class="d-flex justify-content-end mb-2" v-if="help">
        <a
          :href="help"
          target="_blank"
          style="margin-right: 10px"
          ><img
            v-b-tooltip.hover
            title="Veja como fazer!"
            class="img-icon"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
        /></a>
        {{ title }}
      </div>
      <div v-else>
        {{ title }}
      </div>
    </template>

    <slot></slot>

    <template v-slot:modal-footer="{ cancel }">
      <slot name="footer" :cancel="cancel"> </slot>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
    },
    size: {
      type: String,
      default: "lg",
    },
    title: {
      type: String,
      required: true,
    },
    hideFooter: {
      type: Boolean,
    },
    hideHeader: {
      type: Boolean,
    },
    noBackdropClose: {
      type: Boolean,
    },
    noEscClose: {
      type: Boolean,
    }
  },
  methods: {
    shown() {
      this.$emit("shown", true);
      document.querySelector("body").style = "overflow: hidden;";
    },
    hidden() {
      this.$emit("hidden");
      document.querySelector("body").style = "overflow: auto !important; ";
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
  },
};
</script>

<style>
.modal {
  overflow: auto;
}
.modal-content {
  border-radius: 10px !important;
  padding: 20px 30px !important;
  animation: showTop 0.4s forwards !important;
}

#Modal-Templates .modal-content{
  height: 80vh;
}

#edicao-rapida .modal-content {
  height: 80vh;
}

.modal-content .modal-header {
  border-bottom: 1px solid var(--white-medium);
  border-radius: 0;
  padding-bottom: 25px;
  padding-left: 0;
}
.modal-content .modal-header h5 {
  font-size: 16px;
  font-weight: 600;
}
.modal-body {
  padding: 20px 0;
}
.col-auto {
  padding: 0;
}
.modal-content .modal-header .close {
  background: url("../assets/img/icons/fechar.svg") no-repeat center center;
  overflow: hidden;
  text-indent: -100px;
  color: transparent;
  outline: none;
  border: none;
}
.modal-content .modal-footer {
  border-top: 1px solid var(--white-medium);
  border-radius: 0;
  padding-top: 25px;
}

/* modal 100% */
#product-edit {
  padding: 0 !important;
}
#product-edit .modal-xl {
  margin: 0 auto;
  max-width: 100vw;
}
@media (min-width: 1600px) {
  #product-edit .modal-xl {
    max-width: 1300px;
  }
}
#product-edit .modal-content {
  border-radius: 0 !important;
}
#modal-info-aprovacao footer {
  display: none !important;
}
</style>