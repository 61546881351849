<template>
  <BaseModal
    name="LeadsOvertaking"
    id="LeadsOvertaking"
    idModal="LeadsOvertaking"
    size="lg"
    title="Quantidade de leads ultrapassada"
    @shown="openModal"
  >
    <div class="container-flex">
      <div class="text-center align-center">
        <div><img src="@/assets/icons/LeadsOvetaking.svg" /></div>
        <p>
          Parabéns! Você ultrapassou o seu contrato de leads na {{ nameSystem }}!💪<br />
          isso é um ótimo sinal de Crescimento!<br />
          Agora chame nosso time para fazer o upgrade do seu plano.
        </p>
        <p>
          Você também pode optar por fazer uma limpeza nos seus leads e manter o
          <br />
          mesmo contrato, basta deleta-los na tela de Leads.
        </p>
      </div>
    </div>

    <template v-slot:footer="{}">
      <BaseButton variant="link-info" class="mr-4" @click="closeModal">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" class="mr-4" @click="hireResource">
        Contratar recurso
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import contractPlano from "../../services/resources/contractPlan";
const contractPlan = contractPlano.build();

export default {
  data() {
    return {
      recurso: 0,
    };
  },

  computed:{
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },
  methods: {
    openModal() {
      sessionStorage.setItem("ModalLeadsOvertaking", true);
    },
    closeModal() {
      this.$bvModal.hide("LeadsOvertaking");
    },

    hireResource() {
      contractPlan
        .search()
        .then((r) => {
          let name = `${r.first_name} ${r.last_name}`;
          let phone = r.phone;
          let email = r.email;
          let document = r.document;
          let url = `https://gdigital.com.br/g-shopping/?fn=${name}&ph=${phone}&em=${email}&document=${document}&force=true`;
          window.location.href = url;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-flex div {
  display: flex;
  margin-bottom: 0.5em;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  div {
    width: 35%;
    height: 35%;
  }
}
.text-center {
  text-align: center;
  gap: 10px;
}

.align-center {
  align-items: center;
}
</style>
