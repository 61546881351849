var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.GrSidebar,[_c(_setup.GrSideHeader,{ref:"GrSideHeaderRef",scopedSlots:_vm._u([{key:"left",fn:function(){return [_c(_setup.GrSidebarHeaderLeft,{on:{"click":_setup.toogleSide}})]},proxy:true},{key:"right",fn:function(){return [_c(_setup.GrSidebarHeaderRight)]},proxy:true}])}),_c('section',{ref:"GrSidebarRef",class:{ activeSidebar: _setup.activeSidebar },style:({ top: _setup.distanceFromTop }),on:{"mouseleave":_setup.closeSideMenu,"mouseenter":_setup.openSideMenu}},[_c(_setup.GrSideItem,{attrs:{"name":"Dashboard","route":"home","active":_setup.activeDashboard,"hover":_setup.hovered,"resource":!_vm.$store.getters.setPanel},on:{"click":function($event){return _setup.onClickSetItem({
                name: 'Dashboard',
                route: 'home',
                active: false,
                item: 'Dashboard',
              })}}},[_c(_setup.iconSideDashboard,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeDashboard}})],1),(_setup.level == 'templater')?_c(_setup.GrSideItem,{attrs:{"name":"Templates","route":"templates","active":_setup.activeTemplates,"hover":_setup.hovered,"resource":_setup.level == 'templater'},on:{"click":function($event){return _setup.onClickSetItem({
                name: 'Templates',
                route: 'templates',
                active: false,
                item: 'templates',
              })}}},[_c(_setup.iconSideTemplates,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeTemplates}})],1):_vm._e(),(_setup.level != 'templater' && _setup.level != 'blogger' && _setup.level != 'seller')?_c(_setup.GrSideItem,{attrs:{"name":"Estratégico","route":"estrategico","active":_setup.activeEstratégico,"hover":_setup.hovered,"resource":_setup.accessItem('nstrategic')},on:{"click":function($event){return _setup.onClickSetItem({
            name: 'Estratégico',
            route: 'estrategico',
            active: false,
            item: 'Estratégico',
          })}}},[_c(_setup.iconSideEstrategico,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeEstratégico}})],1):_vm._e(),(!_vm.$store.getters.setPanel && _setup.level != 'templater' && _setup.level != 'blogger')?_c(_setup.GrSideItem,{attrs:{"name":"Leads","route":"leads","active":_setup.activeLeads,"hover":_setup.hovered,"resource":_setup.accessItem('nleads')},on:{"click":function($event){return _setup.onClickSetItem({
            name: 'Leads',
            route: 'leads',
            active: false,
            item: 'Leads',
          })}}},[_c(_setup.iconSideLead,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeLeads}})],1):_vm._e(),(_setup.level !== 'templater' && _setup.level !== 'seller' && _setup.level !== 'gerent_seller')?_c(_setup.GrSideItem,{attrs:{"name":"Funis","route":"funis/lista","active":_setup.activeFunis,"hover":_setup.hovered,"resource":true},on:{"click":function($event){return _setup.onClickSetItem({
            name: 'Funis',
            route: 'funis/lista',
            active: false,
            item: 'Funis',
          })}}},[_c(_setup.iconSideFunil,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeFunis}})],1):_vm._e(),(_setup.flagInclude)?_c(_setup.GrSideItem,{attrs:{"name":"Oportunidades","route":"oportunidades","active":_setup.activeOportunidades,"hover":_setup.hovered,"resource":true},on:{"click":function($event){return _setup.onClickSetItem({
            name: 'Oportunidades',
            route: 'oportunidades',
            active: false,
            item: 'Oportunidades',
          })}}},[_c(_setup.iconSideOportunities,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeOportunidades}})],1):_vm._e(),(_setup.level !== 'templater' && _setup.level !== 'blogger')?_c(_setup.GrSideItem,{attrs:{"name":"CRM","route":"crm","active":_setup.activeCRM,"hover":_setup.hovered,"resource":_setup.accessItem('ncrm')},on:{"click":function($event){return _setup.onClickSetItem({
            name: 'CRM',
            route: 'crm',
            active: false,
            item: 'CRM',
          })}}},[_c(_setup.iconSideCRM,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeCRM}})],1):_vm._e(),(_setup.level !== 'templater' && _setup.level != 'blogger')?_c(_setup.GrSideItem,{attrs:{"name":"Aplicativos","route":"apps","active":_setup.activeAplicativos,"resource":true,"hover":_setup.hovered},on:{"click":function($event){return _setup.onClickSetItem({
            name: 'Aplicativos',
            route: 'apps',
            active: false,
            item: 'Aplicativos',
          })}}},[_c(_setup.iconSideApp,{staticClass:"icons-dropdown",attrs:{"active":_setup.activeAplicativos}})],1):_vm._e()],1)],1),_c('main',{staticClass:"main isGreennSvg",class:{ mobile: _setup.isMobile }},[_c('transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('div',{staticClass:"hide-sidebar-mobile",on:{"click":function($event){return _setup.toogleSide()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }