import Rest from '@/services/Rest';

/**
 * @typedef {SellerListService}
 */
export default class SellerListService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'user/users'


}