import Rest from "@/services/Rest";

/**
 * @typedef {UsersService}
 */
export default class UsersService extends Rest {
  /**
   * @type {String}
   */
  static resource = "user";
}
