import Rest from '@/services/Rest';

/**
 * @typedef {TrialService}
 */
export default class TrialService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'trial/remaining'


}