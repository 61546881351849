var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-links",class:{ 'ajust-itens': _vm.$store.getters.setPanel },style:(_vm.level === 'templater' ? 'height:200px' : '')},[_c('div',{staticClass:"menu-actions"},[_c('img',{staticClass:"logo",attrs:{"src":require('@/assets/img/G-digital.png')},on:{"click":_vm.redirectHome}}),(_vm.$store.getters.user.user.level == 'owner' && _vm.can_integrate)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer","position":"relative","top":"-2px","left":"20px"},attrs:{"src":require("@/assets/img/icons/apps-svgrepo-com.svg"),"alt":"dots","title":"Greenn Company Apps"},on:{"click":function($event){return _vm.$router.push({ name: 'Company' })}}}):_vm._e()]),_c('PendingPayment',{attrs:{"title":"PendingPayment"}}),(!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.dashboard))?_c(_vm.$route.path === `/${_vm.dynamicRoute}/home` ? 'a' : 'router-link',{tag:"router-link",class:{
      'router-link-exact-active router-link-active':
        _vm.$route.path === `/${_vm.dynamicRoute}/home`,
    },attrs:{"to":"/dynamicRoute/home"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/dashboard.svg")}}),_vm._v("Home ")]):_vm._e(),(_vm.level !== 'templater' && _vm.level != 'blogger' && _vm.level !== 'seller')?_c(_vm.$route.path === `/${_vm.dynamicRoute}/estrategico` ? 'a' : 'router-link',{tag:"router-link",class:{
      'router-link-exact-active router-link-active':
        _vm.$route.path === `/${_vm.dynamicRoute}/estrategico`,
      'router-link-exact-active router-link-active':
        _vm.$route.path.includes('estrategico'),
    },attrs:{"to":_vm.$store.getters.recursos.nstrategic ? '/dynamicRoute/estrategico' : '/dynamicRoute/AccessDenied'}},[_c('img',{attrs:{"src":require("@/assets/img/icons/plans.svg")}}),_vm._v(" Estratégico "),(!_vm.$store.getters.recursos.nstrategic)?_c('svg',{staticStyle:{"margin-left":"10px"},attrs:{"fill":"#333","height":"16px","width":"16px","version":"1.1","viewBox":"0 0 330 330"}},[_c('g',{attrs:{"stroke-width":"0"}}),_c('g',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round"}}),_c('g',{attrs:{"id":"SVGRepo_iconCarrier"}},[_c('g',{attrs:{"id":"XMLID_509_"}},[_c('path',{attrs:{"id":"XMLID_510_","d":"M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"}})])])]):_vm._e()]):_vm._e(),(!_vm.$store.getters.setPanel && _vm.validLevel('leads'))?_c(_vm.$route.path === `/${_vm.dynamicRoute}/leads` ? 'a' : 'router-link',{tag:"router-link",class:{
      'router-link-exact-active router-link-active':
        _vm.$route.path === `/${_vm.dynamicRoute}/leads`,
      'router-link-exact-active router-link-active':
        _vm.$route.path.includes('lead') && !_vm.$route.path.includes('apps'),
    },attrs:{"to":"/dynamicRoute/leads"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/affiliate.svg")}}),_vm._v("Leads ")]):_vm._e(),(_vm.level == 'templater')?_c(_vm.$route.path === `/${_vm.dynamicRoute}/templates` ? 'a' : 'router-link',{tag:"router-link",class:{
      'router-link-exact-active router-link-active':
        _vm.$route.path === `/${_vm.dynamicRoute}/templates`,
    },attrs:{"to":"/dynamicRoute/templates"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/pages.svg")}}),_vm._v(" Templates ")]):_vm._e(),(
      _vm.level !== 'templater' && _vm.level !== 'seller' && _vm.level !== 'gerent_seller'
    )?_c(_vm.$route.path === `/${_vm.dynamicRoute}/funis/lista` ? 'a' : 'router-link',{tag:"router-link",class:{
      'router-link-exact-active router-link-active':
        _vm.$route.path === `/${_vm.dynamicRoute}/funis/lista`,
      'router-link-exact-active router-link-active':
        _vm.$route.path.includes('funis'),
    },attrs:{"to":'/dynamicRoute/funis/lista'}},[_c('img',{attrs:{"src":require("@/assets/img/icons/filtro_menu.svg")}}),_vm._v("Funis ")]):_vm._e(),(
      _vm.level !== 'templater' &&
      _vm.level !== 'blogger'
    )?_c('router-link',{style:(_vm.$store.getters.recursos.ncrm ? '' : 'cursor: not-allowed'),attrs:{"to":_vm.$store.getters.recursos.ncrm ? `/${_vm.dynamicRoute}/crm` : `/${_vm.dynamicRoute}/AccessDenied`,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/my-sales.svg")}}),_vm._v(" CRM"),(!_vm.$store.getters.recursos.ncrm)?_c('svg',{staticStyle:{"margin-left":"10px"},attrs:{"fill":"#333","height":"16px","width":"16px","version":"1.1","viewBox":"0 0 330 330"}},[_c('g',{attrs:{"stroke-width":"0"}}),_c('g',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round"}}),_c('g',{attrs:{"id":"SVGRepo_iconCarrier"}},[_c('g',{attrs:{"id":"XMLID_509_"}},[_c('path',{attrs:{"id":"XMLID_510_","d":"M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"}})])])]):_vm._e()]):_vm._e(),(_vm.level !== 'templater' && _vm.level != 'blogger')?_c(_vm.$route.path === `/${_vm.dynamicRoute}/apps` ? 'a' : 'router-link',{tag:"router-link",class:{
      'router-link-exact-active router-link-active':
        _vm.$route.path === `/${_vm.dynamicRoute}/apps`,
      'router-link-exact-active router-link-active':
        _vm.$route.path.includes('apps'),
    },attrs:{"to":'/dynamicRoute/apps'}},[_c('img',{attrs:{"src":require("@/assets/img/icons/apps.svg")}}),_vm._v("Aplicativos ")]):_vm._e(),(_vm.level !== 'templater')?_c('a',{attrs:{"href":"https://greenn.crisp.help/pt-br/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/help-circle1.svg")}}),_vm._v(" Ajuda ")]):_vm._e(),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasUpdatedToken),expression:"hasUpdatedToken"}],staticClass:"box-notificacoes"},[_c('img',{attrs:{"src":require("@/assets/img/icons/notfy.svg")}}),_vm._v(" Notificações ")]),_c('DistribuicaoLeads'),_c('DistribuicaoLeadsPorcentagem'),_c('Logout',{attrs:{"isBasic":_vm.isBasic}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }