export default {
  
    authSuccess: (state, access_token) => {
    
        state.access_token = access_token;
        
    },
    authLogout: (state) => {
        state.access_token = null;
    }
}