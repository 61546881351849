<script setup>
import { inject } from "vue";

const toggleDropdown = inject("toggleDropdown");
const sizeDropDown = inject("sizeDropDown");

if (!toggleDropdown) {
  throw new Error("toggleDropdown not provided");
}

if (!sizeDropDown) {
  throw new Error("sizeDropDown not provided");
}

const emit = defineEmits(["click", 'mouseleave', 'mouseover']);

function emitClick() {
  toggleDropdown();
  emit("click");
}
</script>

<template>
  <div
    :class="`size-${sizeDropDown}`"
    class="gr-drop-item"
    tabindex="0"
    @mouseleave="emit('mouseleave',$event)"
    @mouseover="emit('mouseover',$event)"
    @click="emitClick"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.gr-drop-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  background: transparent;
  cursor: pointer;
  border-radius: var(--radius-md);
  padding: var(--spacing-1-5) var(--spacing-2) var(--spacing-1-5)
    var(--spacing-2);
  width: var(--w-full);
  transition: var(--transition);

  &.size-sm {
    padding: var(--spacing-1) var(--spacing-1-5) var(--spacing-1)
      var(--spacing-1-5);
  }

  &.size-md {
    padding: var(--spacing-1-5) var(--spacing-2) var(--spacing-1-5)
      var(--spacing-2);
  }

  &.size-lg {
    padding: var(--spacing-2) var(--spacing-1-5) var(--spacing-2)
      var(--spacing-1-5);
  }

  &:focus-visible {
    transition: none;
    outline: 0px solid transparent;
    background: rgb(229, 231, 231, 0.35);
  }

  &:hover {
    background: rgb(229, 231, 231, 0.35);
  }
}
</style>
