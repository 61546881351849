<template>
  <div class="menu-relative">
    <div class="Menu logo-close" :style="isFreeTrial ? 'height: calc(101vh - 60px); top: 60px;' : ''">
      <Menu :isBasic="isBasic" />
    </div>
  </div>
</template>
<script>
import Menu from "./Menu";
import ResourceCount from "@/services/resources/RecursesService";
const resourceCount = ResourceCount.build();

export default {
  props: ['isFreeTrial'],
  data() {
    return {
      srcLogo: "",
      contracts: [],
    };
  },
  components: {
    Menu,
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function (err) {});
    },
    minimizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
    logo() {
      this.srcLogo = require("@/assets/img/G-digital.png");
      const menu = document.querySelector(".Menu");

      menu.addEventListener("mouseenter", (e) => {
        menu.classList.remove("logo-close");
        this.$root.$emit("exitMenu");
        this.$root.$emit("exitMenu");
      });
      menu.addEventListener("mouseleave", (e) => {
        menu.classList.add("logo-close");
      });
    },
  },
  computed: {
    isBasic() {
      if (!this.contracts) {
        return false
      }
      let contracts = this.contracts.filter(contract => (contract.status == 1 || contract.status == -5) && contract.produto_id >= 118)
      // Se o usuário tiver apenas um contratato e esse contratato for o de teste grátis ou o do plano start
      if (contracts.some(contract => contract.produto_id !== 132 &&  contract.produto_id !== 133 )) {
        return false
      }
      return true
    },
  },
  mounted() {
    this.logo();
    if (!sessionStorage.getItem("userContracts")) {
      resourceCount
        .read("/contrato")
        .then((resp) => {
          this.contracts = resp;
        })
        .catch((err) => {});
    } else {
      this.contracts = JSON.parse(sessionStorage.getItem("userContracts"));
    }
  },
};
</script>

<style scoped>
.flex-itens {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
.flex-itens .item {
  margin-top: 25px !important;
}
.menu-relative {
  position: relative;
  width: 100px;
  /* height: 100vh; */
}
.Menu {
  padding: 20px 40px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-top: none;
  border-radius: none;
  margin: -1px;
  max-width: 100px;
  height: 101vh;
  position: fixed;
  top: 0;
  /* top: calc(100px); */
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s;
  z-index: 1001 !important;
  overflow: hidden;
}
.Menu:hover {
  max-width: 300px;
}

.Menu-logo img {
  margin: 30px auto 20px;
  width: 30px;
  height: auto;
  transition: 0.3s;
  position: relative;
  left: -3px;
}

.after-element {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.after-element::after {
  content: "";
  width: 100px;
  height: 30px;
  background: transparent;
  display: inline-block;
  position: absolute;
  left: 27px;
  top: 30px;
}
/* .logo-close .after-element::after {
  background: #fff;
} */

.logo-close .item {
  display: none;
}
</style>
