import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "@/store/index";
import { replace } from "feather-icons";
import TokenService from "@/services/resources/TokenService";
import Cookies from "js-cookie";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  duplicateNavigationPolicy: 'ignore'
});

export default router;
