import UserLoggedService from "@/services/resources/UserLoggedService";
import UserResourcesService from "@/services/resources/UserResourcesService";
import ListarTagService from "@/services/resources/ListarTagService";
import FormDadosService from "@/services/resources/FormDadosService.js";
import SellerListService from "@/services/resources/SellerListService.js";
import PageListAllService from "@/services/resources/PageListAllService.js";
import DashboardService from "@/services/resources/DashboardService";

import router from "@/router/index";
import getters from "@/store/modules/user/getters";
import Cookies from "js-cookie";

const serviceLogged = UserLoggedService.build();
const serviceResources = UserResourcesService.build();
const serviceTag = ListarTagService.build();
const serviceFormDados = FormDadosService.build();
const serviceSeller = SellerListService.build();
const servicePages = PageListAllService.build();
const serviceDashboard = DashboardService.build();

export default {
  //   validUserRequest: async ({ commit, dispatch }) => {
  //     const user = await serviceLogged.get("");
  //     // console.log("MEU USER PESSOAL", user.user);
  //   },
  userRequest: async ({ commit, dispatch }) => {
    // Load user data
    const user = await serviceLogged.get("");
    await commit("userSuccess", user);

    // console.log("lucas user", user);
    // console.log("lucas user2", user.user.email_verifed_at);

    if (user.user.email_verifed_at == 0) {
      await commit("verifyEmail", false);
    } else {
      await commit("verifyEmail", true);
    }

    // Load user resources data
    const resources = await serviceResources.get("");
    await commit("userResourcesSuccess", resources);
    
    // console.log('faz o commit do tenant_subdomain', );
    
    await commit("tenantSubdomain", user.user.tenant_subdomain);


    // Load all users
    // const dashboard = await serviceDashboard.get("/graph");
    // await commit("userDashboardGraph", dashboard);

    // Redirect to home page
    if (user.user.email_verifed_at == 0) {
      await dispatch("redirectUserToVerify");
    } else if(user.user.email_verifed_at != 0 && user.user.painels.length > 1){
      await dispatch("redirectUserToSelectPainel");
    }else{
      await dispatch("redirectUserToHome");
    }
  },
  userRequest2: async ({ commit, dispatch }) => {
    // Load user data
    const user = await serviceLogged.get("");
    await commit("userSuccess", user);

    await commit("tenantSubdomain", user.user.tenant_subdomain);

    // console.log("lucas user", user);
    // console.log("lucas user2", user.user.email_verifed_at);

    await commit("verifyEmail", true);

    // // Load user resources data
    const resources = await serviceResources.get("");
    await commit("userResourcesSuccess", resources);

    // Load all users
    // const dashboard = await serviceDashboard.get("/graph");
    // await commit("userDashboardGraph", dashboard);

    // Redirect to home page
    if (user.user.email_verifed_at == 0) {
      await dispatch("redirectUserToVerify");
    }else{
      await dispatch("redirectUserToHome2");
    }
  },
  userRequest3: async ({ commit, dispatch }) => {
    // Load user data
    const user = await serviceLogged.get("");
    await commit("userSuccess", user);

    await commit("tenantSubdomain", user.user.tenant_subdomain);

    // console.log("lucas user", user);
    // console.log("lucas user2", user.user.email_verifed_at);

    await commit("verifyEmail", true);

    // Load user resources data
    const resources = await serviceResources.get("");
    await commit("userResourcesSuccess", resources);

    // // Load all users
    // const dashboard = await serviceDashboard.get("/graph");
    // await commit("userDashboardGraph", dashboard);

    // Redirect to home page
    if (user.user.email_verifed_at == 0) {
      await dispatch("redirectUserToVerify");
    }else{
      await dispatch("redirectUserToHome3");
    }
  },
  allDataUser: async ({ commit, dispatch }) => {
    // Load all tags
    const tags = await serviceTag.get("");
    commit("userTagSuccess", tags);

    // Load all forms
    const forms = await serviceFormDados.search({status:"active"});
    commit("userFormSuccess", forms);

    // Load all pages
    const pages = await servicePages.get("");
    commit("userPageSuccess", pages);

    // Load all users
    const users = await serviceSeller.get("");
    commit("userSellerSuccess", users);

    // Load user resources data
    const resources = await serviceResources.get("");
    await commit("userResourcesSuccess", resources);
    
  },

  tagRequest: async ({ commit, dispatch }) => {
    // Load all tags
    const tags = await serviceTag.get("");
    commit("userTagSuccess", tags);
  },

  formRequest: async ({ commit, dispatch }) => {
    // Load all tags
    const forms = await serviceFormDados.search({status:"active"});
    commit("userFormSuccess", forms);
  },

  sellerRequest: async ({ commit, dispatch }) => {
    // Load all tags
    const seller = await serviceSeller.get("");
    commit("userSellerSuccess", seller);
  },

  updateDashboard: async ({ commit, dispatch }) => {
    // Load all users
    const dashboard = await serviceDashboard.get("/graph");
    commit("userDashboardGraph", dashboard);
  },

  updateUser: async ({ commit, dispatch }) => {
    // Load user data
    const user = await serviceLogged.get("");
    await commit("userSuccess", user);
  },

  redirectUserToHome: (context) => {
    var route = `dynamicRoute`;
    if (context.getters.tenantSubdomain) {
      route = context.getters.tenantSubdomain;
    }
    router.push(`/${route}/home${window.location.search}`);
  },
  redirectUserToHome3: (context) => {
    router.replace(`/${Cookies.get("tenantSubdomain")}/home${window.location.search}`);

    window.location.reload();
  },   
  redirectUserToHome2: (context) => {
    var route = `dynamicRoute`;
    if (context.getters.tenantSubdomain) {
      route = context.getters.tenantSubdomain;
    }
    router.push(`/${route}/home${window.location.search}`);
  },
  redirectUserToVerify: (context) => {
    router.push(`/adm/verificar-email`);
  },
  redirectUserToSelectPainel: (context) => {
    router.push(`/adm/selecionar-painel${window.location.search}`);
  },
};
