<template>
  <header>
    <div class="top">
      <h3>{{ title }}</h3>
      <ul style="margin: 0">
        <router-link v-if="navigation" to="/dynamicRoute/home"
          >Dashboard</router-link
        >
        <li v-for="(item, index) in navigation" :key="index">
          <img src="../../src/assets/img/icons/arrowBread.svg" alt="arrow" />
          <router-link class="active" :to="item.to">{{
            item.link
          }}</router-link>
        </li>
      </ul>
    </div>
    <nav>
      <slot></slot>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  props: ['title','navigation'],
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>

<style scoped lang="scss">
.top {
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    gap: 10px;
    a {
      font-size: 14px;
      color: #81858e !important;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        color: #57585c !important;
      }
    }

    li {
      display: flex;
      gap: 10px;

      a {
        font-size: 14px;
        color: #81858e !important;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
          color: #57585c !important;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    ul {
      gap: 4x;
      a {
        font-size: 12px;
      }
      li {
        gap: 4x;
        a {
          font-size: 12px;
        }
      }
    }
  }
}
a {
  cursor: pointer;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  padding-bottom: 0;
  margin: 15px 0;
  position: relative;
  z-index: 999;
}

header h3 {
  font-size: 32px;
  font-weight: 600;
  color: var(--gray01);
  // font-family: "Spectral", serif;
  // font-family: 'Questrial', sans-serif;
  font-family: Montserrat;
  position: relative;
  z-index: 1;
}
header h3::after {
  content: "";
  width: 20px;
  height: 20px;
  background: var(--primary-100);
  position: absolute;
  bottom: 4px;
  left: -7px;
  border-radius: 0.2rem;
  z-index: -1;
}

.btn-header {
  border: none;
  background: var(--greenn);
  color: #fff;
  outline: none;
  font-weight: 600;
  height: 50px;
  padding: 0 42px;
  border-radius: 10px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: none !important;
}
.btn-header:hover {
  transform: scale(1.07);
  box-shadow: none !important;
}
.btn-header:focus {
  box-shadow: none !important;
}
.btn-header img {
  width: 16px;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  header {
    display: block;
  }
  header h3 {
    font-size: 24px;
  }
  header h3::after {
    width: 1rem;
    height: 1rem;
  }
  nav {
    max-width: 100%;
  }
}
</style>
