import moment from "moment";

export default {
  opportunities: {
    data: [],
    current_page: 1,
    last_page: 1,
    to: null,
  },
  pagination: {
    totalPages: 1,
    currentPage: 1,
    perPage: 10,
    total: 20,
    perPageVisible: true,
  },
  filterOpportunities:{
    id: null,
    leadName: null,
    leadEmail: null,
    leadPhone: null,
    status: null,
    startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  losOfGreennSoft: "",
  winOfGreennSofy: "",
  settingProft: {
    key: "PROFIT_ACTIVATED",
    valeu: 0
  },

  opportunitySelected: {},
};
