import store from "@/store/index";
import Cookies from "js-cookie";
import TokenService from "@/services/resources/TokenService";

function isAuthenticated(to, from, next) {
  const dynamicRoute = Cookies.get("tenantSubdomain") || "adm";
  const requestedDynamicRoute = to.params.dynamicRoute;
  const resultRoute = to.fullPath.replace('/'+requestedDynamicRoute, '');
  const params = new URLSearchParams(window.location.search);

  
  if(requestedDynamicRoute !== dynamicRoute && requestedDynamicRoute !== 'adm'  && requestedDynamicRoute !== 'dynamicRoute' && store.getters.isAuthenticated && !params.has('rdrk')){ 
    var service = TokenService.build();
    service
      .login(requestedDynamicRoute)
      .then((resp) => {
        store.dispatch("blogSelected3", resp);
      })
      .catch((err) => {
          history.back();
      });
    return
  }

  if (store.getters.isAuthenticated && to.name !== "verificar-email") {
    if (store.state.user.user) {
      if (store.getters.verifyEmail === false) {
        if (to.name === "verificar-email-token") {
          if(dynamicRoute !== requestedDynamicRoute){
            return next(`/${dynamicRoute}${resultRoute}`) + window.location.search;
          }
        } else {
          if(dynamicRoute !== requestedDynamicRoute){
            if (to.path != from.path) {
              return next(`/${dynamicRoute}/verificar-email${window.location.search}`);
            } else {
              return next(`/${dynamicRoute}${resultRoute}`);
            }
          }
        }
      } else {
        if(dynamicRoute !== requestedDynamicRoute){
          return next(`/${dynamicRoute}${resultRoute}`);
        }
      }
    }
  }

  if (store.getters.isAuthenticated) {
    // console.log(`sksksk`, to, from, next);
    return next();
  }
  return next(`/${window.location.search}`);
}
const authentication = isAuthenticated;

export default authentication;
