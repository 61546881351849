<template>
  <b-form-group :label="label" style="width: 100% !important">
    <multiselect
      style="width: 100% !important"
      v-model="localValue"
      id="base-select"
      @input="selected"
      @select="emitSelect"
      :label="trackname"
      :track-by="trackid"
      :placeholder="placeholder"
      :selectLabel="selectLabel"
      :deselectLabel="deselectLabel"
      :selectedLabel="selectedLabel"
      :open-direction="direction"
      :options="array"
      :multiple="multiple"
      :searchable="searchable"
      :loading="loading"
      :internal-search="true"
      :clear-on-select="false"
      :close-on-select="true"
      :show-no-results="true"
      :hide-selected="false"
      :allow-empty="allowEmpty"
      :disabled="disabled"
      @search-change="$emit('search', $event)"
      @search="$emit('search', $event)"
      @clear="$emit('search', '')"
      @change="localValue = filterObj($event)"
      @remove="removed($event)"
      :preserveSearch="preserveSearch"
    >
      >
      <span slot="noOptions">{{ noOptions }}</span>
      <template slot="option" slot-scope="props" v-if="specificType">
        <template v-if="specificType === 'tags'">
          <div class="option__desc" :class="`select-tag-${props.option.color}`">
            <span class="option__title"><span class="select-tag-dot"></span>{{ props.option.name }}</span>
          </div>
        </template>
        <template v-else-if="specificType === 'sellers'">
          <div class="option__desc select-seller-option">
            <div :style="hasPictureSeller(props.option) ? `background-image: url(${hasPictureSeller(props.option)}); color: transparent` : ''">{{ returnInitialSeller(props.option) }}</div>
            <div>
              <p class="option__title">{{ props.option.first_name }} {{ props.option.last_name }}</p>
              <p class="option__subtitle">{{ props.option.email }}</p>
            </div>
          </div>
        </template>
        <template v-else-if="specificType === 'products'">
          <div class="option__desc select-product-option">
            <div :style="props.option.product_photo ? `background-image: url(${props.option.product_photo}); color: transparent` : ''">{{ props.option.name.slice(0, 2) }}</div>
            <div>
              <p class="option__title">{{ props.option.name }}</p>
              <p class="option__subtitle">{{ Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', useGrouping: true,}).format(props.option.product_value) }}</p>
            </div>
          </div>
        </template>
      </template>
      <template slot="afterList">
        <div
          style="
            height: 43px;
            width: 100%;
            background-color: transparent;
            padding: 2px;
            margin-top: -40px;
          "
          v-observe-visibility="reachedEndOfList1"
        />
      </template>

      <span slot="noResult">{{ noResult }}</span>
    </multiselect>
  </b-form-group>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      localValue: null,
      page: 0,
      render: true,
    };
  },
  created() {
    setTimeout(() => {
      this.localValue = this.filterObj(this.selectModel);
    }, this.timeout);
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    returnid: {
      type: String,
      default: null,
    },
    selectModel: {
      default: null,
    },
    noResult: {
      type: String,
      default: "Nenhum item encontrado.",
    },
    noOptions: {
      type: String,
      default: "Nenhum item",
    },
    direction: {
      type: String,
      default: "bottom",
    },
    deselectLabel: {
      type: String,
      default: "",
    },
    selectLabel: {
      type: String,
      default: "",
    },
    selectedLabel: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    trackname: {
      type: String,
      default: "title",
    },
    trackid: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    array: {
      type: Array,
      default: [],
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    watch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    preserveSearch: {
      type: Boolean,
      default: false,
    },
    timeout: {
      default: 0,
    },
    currentPage: {
      default: 0,
    },
    specificType: {
      type: String,
      default: "",
    },
  },
  methods: {
    // searchItem(value) {
    //   if (value.length === "" || value === "") {
    //     this.page++;
    //     console.log("pagess", this.page);
    //   }
    //   this.$emit("search", value);
    // },
    filterObj(selected) {
      if (typeof selected === "string") {
        return this.array.filter((item) => item[this.returnid] == selected);
      } else {
        return selected;
      }
    },
    reachedEndOfList1(reached) {
      this.$emit("callback", ++this.page);
    },
    selected(event) {
      if (this.returnid == null) {
        this.$emit("change", event);
      } else {
        this.$emit("change", event[this.returnid]);
      }
    },
    emitSelect(event) {
      this.$emit("select", event);
    },
    removed(event) {
      if (this.returnid == null) {
        this.$emit("remove", event);
      } else {
        this.$emit("remove", event[this.returnid]);
      }
    },
    returnInitialSeller(user) {
      if (user.first_name && user.last_name) {
        return `${user.first_name[0]}${user.last_name[0]}`
      } else if (user.first_name) {
        return `${user.first_name[0]}${user.first_name[1]}`
      } else if (user.email) {
        return `${user.email[0]}${user.email[1]}`
      } else {
        return '??'
      }
    },
    hasPictureSeller(user) {
      let picMeta = user.metas?.find(meta => meta.meta_key === 'photo')
      if (picMeta) {
        return picMeta.meta_value
      } else {
        return ''
      }
    }
  },
  watch: {
    selectModel(e) {
      if (this.watch) {
        this.localValue = this.filterObj(this.selectModel);
      }
    },
    currentPage(e){
      this.page = e;
    },
    clear(e) {
      if (e == true) {
        if (typeof selected === "string") {
          this.localValue = "";
          return;
        }
        this.localValue = [];
      }
    },
  },
};
</script>
<style lang="scss">
$tag-colors: (
  info: var(--bluee),
  danger: var(--red),
  warning: var(--orange),
  primary: var(--greenn),
  success: #4b2cbd,
  default: var(--gray02)
);

@mixin tags-dot($color) {
  .select-tag-dot {
    margin-right: 8px;
    margin-bottom: 1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $color;
  }
}

@each $tag, $color in $tag-colors {
  .select-tag-#{$tag} {
    @include tags-dot($color);
  }
}

.select-seller-option, .select-product-option {
  display: flex;
  align-items: center;
  gap: 15px;
  > div:first-child {
    width: 30px;
    height: 30px;
    background-color: var(--greenn2);
    border: 1px solid var(--greenn);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greenn);
    text-transform: uppercase;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  > div:last-child {
    p:last-child {
      font-size: smaller;
    }
    p {
      margin-bottom: 0 !important;
      line-height: 1.3;
    }
  }
}
</style>
