import Rest from '@/services/Rest';

/**
 * @typedef {FormDadosService}
 */
export default class FormDadosService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'form/all'


}