// desktopOnly.js
export default function (to, from, next) {
    const isMobile = window.innerWidth <= 768;
  
    if (isMobile) {
      next({ name: 'IsMobile' }); 
    } else {
      next();
    }
  }
  