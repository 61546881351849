import Cookies from 'js-cookie';

import actions from '@/store/modules/user/actions'
import getters from '@/store/modules/user/getters';
import mutations from '@/store/modules/user/mutations'


const state = {
   blog: (Cookies.get('blog')) ? JSON.parse(Cookies.get('blog')) : {},
   user: (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')) : [], 
   tags: (localStorage.getItem('tags')) ? JSON.parse(localStorage.getItem('tags')) : [], 
   forms: (localStorage.getItem('forms')) ? JSON.parse(localStorage.getItem('forms')) : [], 
   pages: (localStorage.getItem('pages')) ? JSON.parse(localStorage.getItem('pages')) : [], 
   seller: (localStorage.getItem('seller')) ? JSON.parse(localStorage.getItem('seller')) : [], 
   dashboard: (localStorage.getItem('dashboard')) ? JSON.parse(localStorage.getItem('dashboard')) : [], 
   resources: (localStorage.getItem('resources')) ? JSON.parse(localStorage.getItem('resources')) : [],
   verifyEmail: (String(Cookies.get('verify_email')) == "true"),
   tenantSubdomain: Cookies.get('tenantSubdomain'),
}


export default {
    state,
    getters,
    actions,
    mutations,
}
