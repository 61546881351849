<template>
  <BaseModal
    name="Migration"
    id="Migration"
    idModal="Migration"
    size="lg"
    title="Olá, seja bem vindo(a)!"
  >
    <div class="container-flex">
      <div class="text-center align-center">
        <div>
          <svg
            width="303"
            height="221"
            viewBox="0 0 363 281"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="rotate2" filter="url(#filter0_d_447_3)">
              <path
                d="M243.627 234.536C243.627 234.158 243.933 233.851 244.311 233.851H249.785C250.163 233.851 250.469 234.158 250.469 234.536V240.01C250.469 240.388 250.163 240.694 249.785 240.694H244.311C243.933 240.694 243.627 240.388 243.627 240.01V234.536Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate1" filter="url(#filter1_d_447_3)">
              <path
                d="M313.2 184.871C313.35 184.511 313.764 184.34 314.124 184.491L319.344 186.668C319.704 186.818 319.874 187.232 319.724 187.592L317.547 192.811C317.397 193.172 316.983 193.342 316.622 193.192L311.403 191.015C311.043 190.865 310.873 190.451 311.023 190.09L313.2 184.871Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate3" filter="url(#filter2_d_447_3)">
              <path
                d="M289.178 45.2888C289.047 44.9213 289.238 44.5166 289.605 44.3849L294.929 42.4767C295.296 42.345 295.701 42.5361 295.833 42.9036L297.741 48.227C297.873 48.5945 297.681 48.9992 297.314 49.131L291.991 51.0391C291.623 51.1709 291.218 50.9797 291.087 50.6122L289.178 45.2888Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate4" filter="url(#filter3_d_447_3)">
              <path
                d="M72.5612 10.6843C72.5612 10.3064 72.8675 10 73.2454 10H78.7195C79.0974 10 79.4038 10.3064 79.4038 10.6843V16.1584C79.4038 16.5363 79.0974 16.8426 78.7195 16.8426H73.2454C72.8675 16.8426 72.5612 16.5363 72.5612 16.1584V10.6843Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate5" filter="url(#filter4_d_447_3)">
              <path
                d="M77.4487 51.7539C77.4487 51.3683 77.7613 51.0557 78.147 51.0557H81.6381C82.0237 51.0557 82.3363 51.3683 82.3363 51.7539V55.245C82.3363 55.6306 82.0237 55.9433 81.6381 55.9433H78.147C77.7613 55.9433 77.4487 55.6306 77.4487 55.245V51.7539Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate1" filter="url(#filter5_d_447_3)">
              <path
                d="M279.795 217.967C279.795 217.562 280.014 217.234 280.284 217.234H281.261C281.531 217.234 281.75 217.562 281.75 217.967V219.433C281.75 219.838 281.531 220.166 281.261 220.166H280.284C280.014 220.166 279.795 219.838 279.795 219.433V217.967Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate2" filter="url(#filter6_d_447_3)">
              <path
                d="M100.909 228.719C100.909 228.315 101.128 227.986 101.398 227.986H102.375C102.645 227.986 102.864 228.315 102.864 228.719V230.186C102.864 230.591 102.645 230.919 102.375 230.919H101.398C101.128 230.919 100.909 230.591 100.909 230.186V228.719Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate3" filter="url(#filter7_d_447_3)">
              <path
                d="M72.5612 136.833C72.5612 136.428 72.8894 136.1 73.2943 136.1H74.7606C75.1655 136.1 75.4937 136.428 75.4937 136.833V138.299C75.4937 138.704 75.1655 139.032 74.7606 139.032H73.2943C72.8894 139.032 72.5612 138.704 72.5612 138.299V136.833Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate4" filter="url(#filter8_d_447_3)">
              <path
                d="M231.896 35.1711C231.896 34.7662 232.225 34.438 232.63 34.438H234.096C234.501 34.438 234.829 34.7662 234.829 35.1711V36.6374C234.829 37.0423 234.501 37.3705 234.096 37.3705H232.63C232.225 37.3705 231.896 37.0423 231.896 36.6374V35.1711Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate5" filter="url(#filter9_d_447_3)">
              <path
                d="M290.547 108.485C290.547 108.08 290.876 107.752 291.281 107.752H292.747C293.152 107.752 293.48 108.08 293.48 108.485V109.951C293.48 110.356 293.152 110.684 292.747 110.684H291.281C290.876 110.684 290.547 110.356 290.547 109.951V108.485Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate1" filter="url(#filter10_d_447_3)">
              <path
                d="M349.198 180.821C349.198 180.416 349.527 180.088 349.932 180.088H351.398C351.803 180.088 352.131 180.416 352.131 180.821V182.287C352.131 182.692 351.803 183.021 351.398 183.021H349.932C349.527 183.021 349.198 182.692 349.198 182.287V180.821Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate2" filter="url(#filter11_d_447_3)">
              <path
                d="M138.055 24.4184C138.055 24.0135 138.383 23.6853 138.788 23.6853H140.254C140.659 23.6853 140.987 24.0135 140.987 24.4184V25.8847C140.987 26.2896 140.659 26.6179 140.254 26.6179H138.788C138.383 26.6179 138.055 26.2896 138.055 25.8847V24.4184Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate3" filter="url(#filter12_d_447_3)">
              <path
                d="M185.953 251.167C185.953 250.782 186.266 250.469 186.651 250.469H190.142C190.528 250.469 190.841 250.782 190.841 251.167V254.659C190.841 255.044 190.528 255.357 190.142 255.357H186.651C186.266 255.357 185.953 255.044 185.953 254.659V251.167Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate4" filter="url(#filter13_d_447_3)">
              <path
                d="M317.918 127.023C317.918 126.637 318.231 126.325 318.616 126.325H322.107C322.493 126.325 322.806 126.637 322.806 127.023V130.514C322.806 130.9 322.493 131.212 322.107 131.212H318.616C318.231 131.212 317.918 130.9 317.918 130.514V127.023Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate5" filter="url(#filter14_d_447_3)">
              <path
                d="M257.312 15.5858C257.312 15.2002 257.624 14.8876 258.01 14.8876H261.501C261.887 14.8876 262.199 15.2002 262.199 15.5858V19.0769C262.199 19.4626 261.887 19.7752 261.501 19.7752H258.01C257.624 19.7752 257.312 19.4626 257.312 19.0769V15.5858Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate1" filter="url(#filter15_d_447_3)">
              <path
                d="M39.3256 231.617C39.3256 231.232 39.6382 230.919 40.0238 230.919H43.5149C43.9005 230.919 44.2131 231.232 44.2131 231.617V235.108C44.2131 235.494 43.9005 235.807 43.5149 235.807H40.0238C39.6382 235.807 39.3256 235.494 39.3256 235.108V231.617Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate3" filter="url(#filter16_d_447_3)">
              <path
                d="M53.5498 165.24C53.3249 164.921 53.4011 164.48 53.7201 164.255L58.3416 160.996C58.6606 160.771 59.1017 160.847 59.3267 161.166L62.5858 165.788C62.8108 166.107 62.7345 166.548 62.4155 166.773L57.794 170.032C57.475 170.257 57.0339 170.181 56.809 169.862L53.5498 165.24Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate2" filter="url(#filter17_d_447_3)">
              <path
                d="M10.4074 109.428C10.1824 109.109 10.2586 108.668 10.5777 108.443L18.0876 103.147C18.4066 102.922 18.8476 102.998 19.0726 103.317L24.3687 110.827C24.5937 111.146 24.5174 111.587 24.1984 111.812L16.6885 117.108C16.3695 117.333 15.9284 117.257 15.7034 116.938L10.4074 109.428Z"
                fill="#4ea934"
              />
            </g>
            <g class="rotate4" filter="url(#filter18_d_447_3)">
              <path
                d="M97.4799 261.451C97.7316 261.153 98.1775 261.115 98.476 261.367L102.8 265.011C103.098 265.263 103.136 265.709 102.885 266.007L99.2399 270.331C98.9883 270.63 98.5423 270.668 98.2438 270.416L93.92 266.771C93.6215 266.52 93.5835 266.074 93.8352 265.775L97.4799 261.451Z"
                fill="#4ea934"
              />
            </g>
            <path
              d="M145.833 182.167L126.5 162.833L145.833 143.5"
              stroke="#4ea934"
              stroke-width="7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M213.5 133.833V143.5C213.5 148.628 211.463 153.545 207.837 157.171C204.212 160.796 199.294 162.833 194.167 162.833H126.5"
              stroke="#4ea934"
              stroke-width="7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M194.167 75.8334L213.5 95.1667L194.167 114.5"
              stroke="#4ea934"
              stroke-width="7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M126.5 124.167V114.5C126.5 109.372 128.537 104.455 132.163 100.829C135.788 97.2035 140.706 95.1666 145.833 95.1666H213.5"
              stroke="#4ea934"
              stroke-width="7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <filter
                id="filter0_d_447_3"
                x="233.627"
                y="223.851"
                width="26.8427"
                height="26.8427"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_447_3"
                x="300.968"
                y="174.436"
                width="28.8103"
                height="28.8103"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_d_447_3"
                x="279.137"
                y="32.4351"
                width="28.6458"
                height="28.6458"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter3_d_447_3"
                x="62.5612"
                y="0"
                width="26.8427"
                height="26.8427"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter4_d_447_3"
                x="67.4487"
                y="41.0557"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter5_d_447_3"
                x="269.795"
                y="207.234"
                width="21.9551"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter6_d_447_3"
                x="90.9092"
                y="217.986"
                width="21.9551"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter7_d_447_3"
                x="62.5612"
                y="126.1"
                width="22.9325"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter8_d_447_3"
                x="221.896"
                y="24.438"
                width="22.9325"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter9_d_447_3"
                x="280.547"
                y="97.7517"
                width="22.9325"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter10_d_447_3"
                x="339.198"
                y="170.088"
                width="22.9326"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter11_d_447_3"
                x="128.055"
                y="13.6853"
                width="22.9325"
                height="22.9325"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter12_d_447_3"
                x="175.953"
                y="240.469"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter13_d_447_3"
                x="307.918"
                y="116.325"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter14_d_447_3"
                x="247.312"
                y="4.88757"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter15_d_447_3"
                x="29.3256"
                y="220.919"
                width="24.8876"
                height="24.8876"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter16_d_447_3"
                x="43.4205"
                y="150.867"
                width="29.2946"
                height="29.2944"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter17_d_447_3"
                x="0.278076"
                y="93.0178"
                width="34.2198"
                height="34.2197"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
              <filter
                id="filter18_d_447_3"
                x="83.6687"
                y="251.2"
                width="29.3824"
                height="29.3823"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0.894118 0 0 0 0 0.627451 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_447_3"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_447_3"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <p>
          Vi que você possui uma conta na G Digital 1.0! 😁
        </p>
        <p>
          Deseja realizar a importação de seus Leads, Formularios, Pipelines, Tags e Campanhas? 
          <br>
          <br>
          Você também pode optar por cancelar se seguir o fluxo normalmente.
          <br />
        </p>
      </div>
    </div>

    <template v-slot:footer="{}">
      <BaseButton variant="link-info" class="mr-4" @click="closeModal">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" class="mr-4" @click="hireResource">
        Importar dados
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import contractPlano from "../../services/resources/contractPlan";
const contractPlan = contractPlano.build();

export default {
  data() {
    return {
      recurso: 0,
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("Migration");
    },

    hireResource() {
      contractPlan
        .search()
        .then((r) => {
          let name = `${r.first_name} ${r.last_name}`;
          let phone = r.phone;
          let email = r.email;
          let document = r.document;
          let url = `https://gdigital.com.br/g-shopping/?fn=${name}&ph=${phone}&em=${email}&document=${document}&force=true`;
          window.location.href = url;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-flex div {
  display: flex;
  margin-bottom: 0.5em;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  div {
    width: 100%;
    display: contents;
  }
}
.text-center {
  text-align: center;
  gap: 10px;
}

.align-center {
  align-items: center;
}

.rotate1{
  animation: rotate 5s;
}

.rotate2{
  animation: rotate 3s;
}

.rotate5{
  animation: rotate 2s;
}

.rotate3{
  animation: rotate 4s;
}

.rotate4{
  animation: rotate 7s;
}


@keyframes rotate {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(1turn)
    }
}
</style>
