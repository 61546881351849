export default {
  upDateProductIdMakeQustion:(state, productId) => {
    state.makeQuestion.productId = productId;
  },
  upDateQuestionIdMakeQustion: (state, questionId) => {
    state.makeQuestion.questionId = questionId;
  },
  upDateTextQuestion: (state, text) => {
    state.makeQuestion.question = text;
  },
  upDateTextResponse: (state, text) => {
    state.makeQuestion.response = text;
  }
}