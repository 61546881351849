<script setup>
import { inject } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

function handleClick() {
  if (props.disabled) return;
  toggleDropdown();
}

const toggleDropdown = inject("toggleDropdown");
</script>

<template>
  <div class="gr-drop-trigger-icon" :class="{ disabled: props.disabled }" @click="handleClick">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.gr-drop-trigger-icon {
  display: flex;
  gap: var(--spacing-3);
  background: transparent;

  &.disabled {
    opacity: 0.5;
  }
}
</style>
