import Rest from '@/services/Rest';

/**
 * @typedef {RecursesService}
 */
export default class RecursesService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'recurso'
}
